import React, { useState } from 'react'
import {Container, Row, Col } from 'react-bootstrap'

import { StContainerPerfil } from "./style"

import MenuTop from '../../components/MenuTop/MenuTop'
import MenuBottom from '../../components/MenuBottom/MenuBottom'


import CardAnuncio from '../../components/CardAnuncio/CardAnuncio'
import PontosBlock from '../../components/Pontos/PontosBlock'


import startIcon from '../../assets/images/star.svg'

import avatar2 from '../../assets/images/avatar2.png';
import uploadBtn from '../../assets/images/upload.svg';
import masterCard from '../../assets/images/mastercard.svg';
import MenuDesktop from '../../components/MenuDesktop/MenuDesktop'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop';

import warningIcon from '../../assets/images/warning.svg';
import arrowRed from '../../assets/images/arrow-red.svg';
import arrowGreen from '../../assets/images/arrow-green.svg';

import closeIcon from '../../assets/images/close-icon.png';

import noAvatar from '../../assets/images/noavatar.jpg';

import Modal from '../../components/ModalDeleteUser/ModalDeleteUser'

export default function Perfil(){
    const [isOpen, setIsOpen] = useState(false)


    function onClickCloseError(){
        setIsOpen(false)
    }
    function onClickOpen(){
        setIsOpen(true)
    }

    return(
        <>

            <MenuTop />
            <MenuDesktop />


            <StContainerPerfil>

                <Container fluid>
                    <Container>
                        <Row>
                            <Col><h5 className="perfil-title">Seu perfil</h5></Col>
                        </Row>
                        <Row>
                            <Col xs={5} lg={2}>
                                {localStorage.getItem('avatar') ?
                                 (<><img src={localStorage.getItem('avatar')} style={{width:'80px', borderRadius:'50%'}} className="img-fluid" alt="user photo" /></>)
                                  :
                                 (<><img src={noAvatar} style={{width:'80px', borderRadius:'50%'}} className="img-fluid" alt="user photo" /></>)}
                                
                            </Col>   
                            <Col xs={7}>
                                <Row>
                                <label for="upload-photo"  className="img-fluid">
                                    <img src={uploadBtn} />
                                </label>
                                 <input type="file" id="upload-photo" name="file"/>   
                                </Row>
                                <Row>
                                    <span className="text-upload">Tamanho máximo do arquivo: 2 MB. <br /> Extensões aceitas: JPG, PNG e JPEG. </span>
                                </Row>
                            </Col> 
                        </Row>  



                        <Row>
                            <Col xs={12} lg={6}>
                                <form className="form-perfil">
                                <Col xs={12} lg={8} className="form-group">
                                    <label for="exampleInputPassword1">Nome</label>
                                    <input type="text" class="form-control" id="name" placeholder="Vitória Ferreira" />
                                </Col>
                                <Col xs={12} lg={8} className="form-group">
                                    <label for="exampleInputEmail1">Email</label>
                                    <input type="email" class="form-control" id="email"  placeholder="seuemail@tvalviverde.com.br" />
                                    
                                </Col>
                                <Col className="alterar-senha-text pb-2 d-lg-none">
                                        <span className="alterar-senha-text"><a href="#">Alterar senha </a><img style={{width:'10px'}} src={arrowGreen} /></span>
                                </Col>
                                </form>   
                            </Col>

                            <Col>     

                                
                                <Row className="box-border-mobile">
                                    <Col xs={12} className="fix-desktop-dados"><span>Alterar dados do cartão</span></Col>
                                    <Col xs={12} className="d-flex mt-2 mb-2">
                                        <Col xs={2} className="p-0">
                                        <span>  
                                            <img src={masterCard} />
                                        </span>
                                        </Col>
                                        <Col xs={8} className="text-left p-0"><span>Mastercard **** 3947</span></Col>
                                        <Col xs={2} className="p-0 text-right edit-password"><span><a href="#">Editar</a></span></Col>
                                    </Col>
                                    <Col xs={12} className="d-flex">
                                        <Col xs={{
                                            span:10, offset:2
                                        }} className="p-0 text-left"><span><img width={16} src={warningIcon} /> A sua assinatura expira no dia 28/02/2023.</span></Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>  

                        <Row>
                                <Col className="alterar-senha-text pb-2 d-none d-lg-block">
                                    <span className="alterar-senha-text"><a href="#">Alterar senha </a><img style={{width:'10px'}} src={arrowGreen} /></span>
                                </Col>
                                <Col><button className="btn-exclui-conta d-none d-lg-block" onClick={onClickOpen}>Excluir conta <img style={{width:'10px', margin:'0 4px'}} src={arrowRed} /></button></Col>
                        </Row>                       
                                
                        <Row className="btn-box">
                            <Col  xs={12} lg={12} className="d-flex justify-content-center align-items-center p-2">
                                <button style={{width:'300px', maxWidth:'100%'}} className='btn-perfil'>SALVAR</button>
                            </Col>
                            <Col><button className="btn-exclui-conta d-lg-none">Excluir conta <img style={{width:'10px', margin:'0 4px'}} src={arrowRed} /></button></Col>
                        </Row>
                        <Row>
                        </Row>
                    </Container>    
                </Container>    
            
            </StContainerPerfil>


            <MenuBottom />
            <FooterDesktop  />       

            <Modal isOpen={isOpen} close={onClickCloseError}>
                <Container className="btn-area_next regulamento">
                    <Row className="texto-modal">
                        <Col className="d-flex justify-content-end mt-3">
                            <button className="btn-close-1"  onClick={onClickCloseError}>
                                <img src={closeIcon} />
                            </button>
                        </Col>
                    </Row>
                    <Row className="texto-modal pt-3 pb-3">
                        <Col>
                            <span className="modal-title">
                                Tem certeza?
                            </span>
                        </Col>
                    </Row>
                    <Row className="texto-modal pt-3 pb-3">
                        <Col>
                            <span className="modal-text">
                            Para confirmar a exclusão da sua <br /> conta digite abaixo o seu e-mail:
                            </span>
                        </Col>
                    </Row>
                    <Row className="texto-modal pt-3 pb-3">
                        <Col>
                            <input type="email" className="form-control" placeholder="seuemail@tvalviverde.com.br" />  
                        </Col>
                    </Row>
                    <Row className="texto-modal pt-3 pb-4">
                    <Col className="d-flex justify-content-between">
                        <button type="submit" className="btn-close-2" onClick={onClickCloseError}>
                            <span className="button-content">FECHAR</span>
                        </button>
                        <button type="submit" className="btn-deletar" onClick={onClickCloseError}>
                            <span className="button-content">SIM, DELETAR</span>
                        </button>
                    </Col>
                    </Row>
                </Container>
            </Modal>               

        </>
    )
}

