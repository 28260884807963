import styled from 'styled-components'



export const StTitleAnuncio = styled.h5`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin:0 ;
`

export const StTextAnuncio = styled.span`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #FFFFFF;
`
export const StCarouselBlock = styled.div`
    background-color: #D4D5D4;
    width: 100%;
    height: 100px;
    text-align: center;
    display:flex ;
    align-items: center;
    justify-content: center;
`
export const FluidAnuncio = styled.div`
    background-color: ${props => props.theme.colors.primary};



`