import { StContainerPublicRedefinirSenhaEmail } from "./style"
import headerLogo from '../../assets/images/header.svg'
export default function redefinirSenhaEmail(){
    return(
        <StContainerPublicRedefinirSenhaEmail className="box-public-screen">
        <div className="header-secundary">
            <img width="100%" src={headerLogo} />
        </div>
        <div className="body-section">
            <h5>Cadastre uma nova senha</h5>
            <p className="required-text-password">Preencha os campos abaixo <br /> com a sua nova senha.</p>
            <div className="login-inputs">
                <div>
                    <label>Nova Senha*</label>
                        <input type="password" placeholder="*****************" />
                </div>
                <div>
                    <label>Confirme a nova senha*</label>
                        <input type="password" placeholder="*****************" />
                </div>

                
                 <div>
                 <p className="pattern-password-text">Sua senha deve ter no mínimo 8 caracteres e pelo <br /> menos uma letra maiúscula, uma letra minúscula, <br /> um número e um caractere especial.</p>
                 </div>
                
                
                <div className="btn-go">
                    <button>SALVAR</button>
                </div>

                <div className="required-text-one">
                    <p>*Todos os campos são obrigatórios.</p>
                </div>
                
=
                <div className="required-text-one">
                    <p>*Dúvidas? Escreva para:</p>
                    <p><a href="#">atendimento@bolaoalviverde.com.br</a></p>

                </div>
            </div>

        </div>
</StContainerPublicRedefinirSenhaEmail>
)
}
