import styled from 'styled-components'



export const StCotainerMain = styled.section`
        margin-top: 80px;



.anuncio-container-main-info {
        display:none;
}

.home-green-container {
        background-color:${props => props.theme.colors.primary  };
}
.media-body-desktop h5.user-hello {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;

        color: #FFFFFF;
}
span.user-welcome {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;

        color: #FFFFFF;
}
.ranking-info {
        background-color: #282828;
        border-radius:10px;
        padding-top:10px;
        padding-bottom: 10px;
}
.box-ranking-position {
        background-color:${props => props.theme.colors.secundary};
        border-radius:10px;
}
.position-info {
        background-color:${props => props.theme.colors.secundary};
        border-radius:10px;
}
.position-info span {


        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        /* identical to box height */

        display: flex;
        align-items: center;

        color: #FFFFFF;
}
span.ranking-info-description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;

        color: #FFFFFF;
}
/* .anuncio-home-container-slide {
        width:100% !important;
} */

.premio-da-vez {
        border-radius: 7px;
        display:flex ;
        align-items: center;
        justify-content: center;
        color:${props => props.theme.colors.primary} ;
}


.box-ranking-position.mobile {
        position: absolute;
        right: 0;
}
.box-ranking-position.mobile .position-info {
        border-radius: 0;
}

.media-body-mobile  h5.user-hello {
        font-size: 2.9vw !important;
}

@media(max-width:991px){

        .box-slide-premiacao {
                flex-direction: column-reverse;
        }
}

/* @media(max-width:991px){
        .anuncio-home-container-slide {
            padding:0;
        }
    } */

    @media(max-width:991px){
    .fluid-anuncio.container-fluid {
        padding: 0;
    }
    .anuncio-home-container-slide.col-lg-12.col-12 {
        padding: 0;
    }
    .box-anuncio-out {
        padding:0;
    }
    }
`



