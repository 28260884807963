import styled from 'styled-components'



export const StContainerCampeonatos = styled.section`
    margin-top: 120px;
    color: #000;


    span.campeonatos-title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;

        /* Verde Palmeiras – 01 */

        color: ${props => props.theme.colors.primary};

    }

    span.campeonatos-desc {


        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 14px;

        /* Cor – Texto Corrido */

        color: #282828;
    }
    .card {
        border:none;
        margin: 20px 0;
    }
    .card-body {
        padding: 0;
    }
    .collapse.show {
    border-bottom: 1px solid #ccc;
    overflow:auto ;
    }
    .card-header {
        background:transparent ;
       border:0 ;
       box-shadow: none;
    }   
    .card-header{
        padding: 0;
    }
    .accordion>.card:first-of-type {

        border-top: 0;
    }
    .meu-time {
        background-color: ${props => props.theme.colors.primary} !important;
        color:#fff;
    }
    .card-header {
        cursor:pointer;
    }
`   