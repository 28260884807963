import styled from 'styled-components'

export const StMenu = styled.div`
background: ${props => props.theme.colors.secundary };
position: fixed;
width:100%;
display:flex;
flex-wrap: wrap;
height:80px;
text-align:center ;
top:0;
z-index: 9999999;
box-shadow: 0px 1px 8px #695b5b;
.menu-hamburguer {
    display: flex;
    align-items:center;
}
button {
    background: none;
    border:none;
    outline:none;
}
.menu-full {
    background: #282828;
    position: absolute;
    height: 100vh;
    width: 100%;
    overflow: auto;
}
.menu-logo-closed img {
    height: 80px;
    width: 300px;
    max-width: 100%;
}
.media span a {
    color: #fff;
}
`