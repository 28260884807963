import styled from 'styled-components'

export const StMenuBottom = styled.div`
position:fixed;
bottom: 0;
width: 100%;
z-index: 199;

.main-box {
    background-color:#000 ;
}

`