import React, { useState } from 'react'
import {Container, Row, Col } from 'react-bootstrap'

import { StContainerVideos } from "./style"

import MenuTop from '../../components/MenuTop/MenuTop'
import MenuBottom from '../../components/MenuBottom/MenuBottom'

import CardAnuncio from '../../components/CardAnuncio/CardAnuncio'
import RankingBlock from '../../components/Ranking/RankingBlock'

import RankingIcon from '../../assets/images/graph.svg'
import MenuDesktop from '../../components/MenuDesktop/MenuDesktop'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'

import video1 from '../../assets/images/video1.png';



export default function Videos(){

    return(
        <>

            <MenuTop />
            <MenuDesktop />


            <StContainerVideos>
              <Container fluid>
                <Container>
                    <Row className="container-title-desc mt-5">
                        <Col lg={{span:10, offset:1}}>
                            <h4>TV Alviverde</h4>
                            <h6 className='pb-4'>Transmitindo a emoção de ser palmeirense desde 2007.</h6>
                            <span >Confira os últimos vídeos do canal</span>
                        </Col>
                    </Row>
                    <Row className="mt-5 mb-5">
                        <Col lg={{span:10, offset:1}} xs={12} className="">
                        <Row>
                            <Col lg={6} xs={12} className="card-videos mt-3">
                                <Row>
                                    <Col>
                                        <img src={video1} alt="" />
                                    </Col>
                                    <Col className="d-flex align-items-center row">
                                        <span className="video-title">DEBATENDO A VIRADA CONTRA O SÃO PAULO</span>
                                        <span className='play-now2 '>Assista</span>
                                    </Col>
                                </Row>
                            </Col>


                            <Col lg={6} xs={12} className="card-videos mt-3">
                                <Row>
                                    <Col>
                                        <img src={video1} alt="" />
                                    </Col>
                                    <Col className="d-flex align-items-center row">
                                        <span className="video-title">DEBATENDO A VIRADA CONTRA O SÃO PAULO</span>
                                        <span className='play-now2 '>Assista</span>
                                    </Col>
                                </Row>
                            </Col>


                            <Col lg={6} xs={12} className="card-videos mt-3">
                                <Row>
                                    <Col>
                                        <img src={video1} alt="" />
                                    </Col>
                                    <Col className="d-flex align-items-center row">
                                        <span className="video-title">DEBATENDO A VIRADA CONTRA O SÃO PAULO</span>
                                        <span className='play-now2 '>Assista</span>
                                    </Col>
                                </Row>
                            </Col>


                            <Col lg={6} xs={12} className="card-videos mt-3">
                                <Row>
                                    <Col>
                                        <img src={video1} alt="" />
                                    </Col>
                                    <Col className="d-flex align-items-center row">
                                        <span className="video-title">DEBATENDO A VIRADA CONTRA O SÃO PAULO</span>
                                        <span className='play-now2 '>Assista</span>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        </Col>
                    </Row>
                </Container>
              </Container>
            </StContainerVideos>



            <MenuBottom />
            <FooterDesktop />

        </>
    )
}

