import styled from 'styled-components'

export const StContainerPublicCadastroDireto = styled.div`
    background-color: #fff;
    height:100vh;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media(min-width:992px){

    border-left: 200px solid ${props => props.theme.colors.primary};
    border-right: 200px solid ${props => props.theme.colors.primary};
    }
    @media(max-width:991px){

    border-left: 20px solid ${props => props.theme.colors.primary};
    border-right: 20px solid ${props => props.theme.colors.primary};
    }

    .body-section {
        /* overflow:auto; */
        background-color: #fff;
        overflow: auto;
    }
    h5 {
        margin:0;
        padding:0 ;
        text-align:center;
        color: ${props => props.theme.colors.primary};
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 21px;
    }
    .body-section{
        flex: 1;
        
    }
    .login-inputs div {
        width: 80%;
        overflow: hidden;
    }
    .links-section p{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;

        color: #282828;
    }
    .links-section p a {
        font-weight: bold;
        color: #000;
    }
    .block-inputs {
        margin-bottom: 25px;
    }
    .msgs-error-validate {
        color: red;
    }
    .login-inputs input {
        /* margin: 0 0 28px 0; */
        width:100% ;
        border:none;
        border-bottom: 1px solid #000;
        background-color: transparent;
        outline: none;
        padding: 12px 0%;
    }
    .login-inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    label{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;

        /* Cor – Texto Corrido */
 
        color: #282828;
    }
    .btn-go {
        margin:30px 0 ;
        text-align:center;
    } 
    .btn-go button {
        background-color: ${props => props.theme.colors.primary};
        padding: 15px 90px;
        border: none;
        border-radius: 5px;

        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 15.2924px;

        color: #FFFFFF;
        cursor: pointer;
    }
    .required-text-one {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align:center;

        color: #282828;
    }
    .required-text-one a {
        color:${props => props.theme.colors.primary} ;
    }
    .text-dados-p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        margin-bottom: 20px;

        /* Cor – Texto Corrido */

        color: #282828;
    }
    .info-senha{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        /* or 142% */


        color: #7A7A7A;
    }
    .back-login {
        text-align: center;
    }
    .back-login a {
        color:${props => props.theme.colors.primary};
        text-decoration: none;
        font-family: 'Lato';
        font-style: normal;
    }
    .text-regulamento-p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        width:100%  !important;

        /* Cor – Texto Corrido */

        color: #282828;
    }
    .text-regulamento-p p {
        text-align: center;
    }
    .regulamento-text {
        overflow: auto !important;
        margin: 40px 0;
        height: 300px;
        padding: 20px 10px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 25px;
        width:90% !important   ;
        /* or 208% */


        /* Cinza 02 */

        color: #707170;
    }
    .login-inputs .checkbox  {
        display:flex;
        align-items: center;
    }
    .checkbox label {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #282828;
    }
    .login-inputs .checkbox input {
        width: unset;
        margin-right:10px;
    }
    .btn-go button:disabled{
        background-color: #ccc;
    }



    @media(min-width:992px){
        .header-secundary {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .box-public-screen {
            border-left: 200px solid ${props => props.theme.colors.primary};
            border-right: 200px solid ${props => props.theme.colors.primary};
        }
    }

    
`