import React, { useState } from 'react'
import {Container, Row, Col } from 'react-bootstrap'

import { StContainerGanhadores } from "./style"

import MenuTop from '../../components/MenuTop/MenuTop'
import MenuBottom from '../../components/MenuBottom/MenuBottom'


import MenuDesktop from '../../components/MenuDesktop/MenuDesktop'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'

import vencedor from '../../assets/images/vencedor.png';



export default function Videos(){

    return(
        <>

            <MenuTop />
            <MenuDesktop />


            <StContainerGanhadores>
            <Container fluid>
                <Container>
                    <Row className="container-title-desc mt-5">
                        <Col lg={{span:10, offset:1}}>
                            <h4>Confira os vencedores!</h4>
                            <h6 className='pb-4'>A última premiação rolou no dia 05/12, e premiou os quatro melhores <br /> colocados do ranking em 2022. Veja quem levou os prêmios: .</h6>
                        </Col>
                    </Row>
                </Container>
            </Container>
              <Container fluid className="container-ganhadores-main">
                <Container>
                    <Row className="mt-5 mb-5 d-flex">
                        <Col lg={{span:10, offset:1}} xs={12}>
                        <Row>
                            <Col lg={6} xs={12} className="card-videos mt-3">
                                <Row>
                                    <Col>
                                        <img src={vencedor} alt="" />
                                    </Col>
                                    <Col className="d-flex align-items-center row">
                                        <span className="ganhador-title">PRIMEIRO LUGAR</span>
                                        <span className='ganhador-play-now2 '>Marcos Paulo</span>
                                    </Col>
                                </Row>
                            </Col>


                            <Col lg={6} xs={12} className="card-videos mt-3">
                                <Row>
                                    <Col>
                                        <img src={vencedor} alt="" />
                                    </Col>
                                    <Col className="d-flex align-items-center row">
                                        <span className="ganhador-title">SEGUNDO LUGAR</span>
                                        <span className='ganhador-play-now2 '>Marta Silva</span>
                                    </Col>
                                </Row>
                            </Col>


                            <Col lg={6} xs={12} className="card-videos mt-3">
                                <Row>
                                    <Col>
                                        <img src={vencedor} alt="" />
                                    </Col>
                                    <Col className="d-flex align-items-center row">
                                        <span className="ganhador-title">TERCEIRO LUGAR</span>
                                        <span className='ganhador-play-now2 '>Jonatas Santos</span>
                                    </Col>
                                </Row>
                            </Col>


                            <Col lg={6} xs={12} className="card-videos mt-3">
                                <Row>
                                    <Col>
                                        <img src={vencedor} alt="" />
                                    </Col>
                                    <Col className="d-flex align-items-center row">
                                        <span className="ganhador-title">QUARTO LUGAR</span>
                                        <span className='ganhador-play-now2 '>Lunático Palmeirense</span>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        </Col>
                    </Row>
                </Container>
              </Container>
            </StContainerGanhadores>



            <MenuBottom />
            <FooterDesktop />

        </>
    )
}

