import {
    BrowserRouter,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import {isAuthenticated} from './services/auth'

import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Cadastro from './pages/Cadastro/Cadastro';
import CadastroDireto from './pages/CadastroDireto/CadastroDireto';
import RedefinirSenha from './pages/RedefinirSenha/RedefinirSenhaEmail';
import RedefinirSenhaPass from './pages/RedefinirSenha/RedefinirSenha';

import Ranking from './pages/Ranking/Ranking'
import Pontos from './pages/Pontos/Pontos'
import ComoFunciona from './pages/ComoFunciona/ComoFunciona';
import Perfil from './pages/Perfil/Perfil';
import Palpite from './components/Palpite/Palpite';
import Campeonatos from './pages/Campeonatos/Campeonatos';
import Videos from './pages/Videos/Videos';
import Ganhadores from './pages/Ganhadores/Ganhadores';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
          // <Component {...props} />
        )
      }
    />
  );

const PublicRoute = ({ component: Component, ...rest }) => (
<Route
    {...rest}
    render={props =>
    isAuthenticated() ? (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
    ) : (
        <Component {...props} />
    )
    }
/>

);

export function AppRoute(){
    return(
        <BrowserRouter>
            <Switch>
                <PublicRoute path="/login" exact component={Login}/>
                <PublicRoute path="/register" exact component={Cadastro} />
                <PublicRoute path="/cadastro" exact component={CadastroDireto} />
                <PublicRoute path="/redefinir-senha" exact component={RedefinirSenha} />
                <PublicRoute path="/nova-senha" exact component={RedefinirSenhaPass} />
                <PrivateRoute path="/" exact component={Home}/>
                <PrivateRoute path="/ranking" exact component={Ranking} />
                <PrivateRoute path="/pontos" exact component={Pontos} />
                <PrivateRoute path="/como-funciona" exact component={ComoFunciona} />
                <PrivateRoute path="/perfil" exact component={Perfil} />
                <PrivateRoute path="/palpite" exact component={Palpite} />
                <PrivateRoute path="/campeonatos" exact component={Campeonatos} />
                <PrivateRoute path="/videos" exact component={Videos} />
                <PrivateRoute path="/ganhadores" exact component={Ganhadores} />

            </Switch>
        </BrowserRouter>
    )
}