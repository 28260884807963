import { StRanking } from "./style"
import {Container, Row, Col } from 'react-bootstrap'

import user from '../../assets/images/user.png'
export default function RankingBlock(){
    return(

        <StRanking>
            <Container style={{paddingBottom:'80px'}}>
            <div>
                <Row className="info-card-titles">
                    <Col className="d-flex justify-content-around">
                    <div>
                        
                    </div>
                    <div></div>
                    </Col>
                    <Col className="d-flex justify-content-around">
                    <div className="">Pontos</div>
                    <div className="">Posição</div>
                    </Col>
                </Row>

                <Row className="card-ranking-item-owner">
                    <Col className="d-flex justify-content-around">
                    <div>
                        <img src={user}/>
                    </div>
                    <div>Marcel Santos</div>
                    </Col>
                    <Col className="d-flex justify-content-around">
                    <div className="pontos-box">344</div>
                    <div className="posicao-box">1º</div>
                    </Col>
                </Row>
                </div>


                <div>
                <Row className="info-card-titles">
                    <Col className="d-flex justify-content-around">
                    <div>
                        
                    </div>
                    <div>Ranking Geral</div>
                    </Col>
                    <Col className="d-flex justify-content-around">
                    <div className="">Pontos</div>
                    <div className="">Posição</div>
                    </Col>
                </Row>

                <Row className="card-ranking-item card-item-ranking-1">
                    <Col className="d-flex justify-content-around">
                    <div>
                        <img src={user}/>
                    </div>
                    <div>Marcel Santos</div>
                    </Col>
                    <Col className="d-flex justify-content-around">
                    <div className="pontos-box">344</div>
                    <div className="posicao-box">1º</div>
                    </Col>
                </Row>
                <Row className="card-ranking-item card-item-ranking-1">
                    <Col className="d-flex justify-content-around">
                    <div>
                        <img src={user}/>
                    </div>
                    <div>Marcel Santos</div>
                    </Col>
                    <Col className="d-flex justify-content-around">
                    <div className="pontos-box">344</div>
                    <div className="posicao-box">2º</div>
                    </Col>
                </Row>
                <Row className="card-ranking-item card-item-ranking-1">
                    <Col className="d-flex justify-content-around">
                    <div>
                        <img src={user}/>
                    </div>
                    <div>Marcel Santos</div>
                    </Col>
                    <Col className="d-flex justify-content-around">
                    <div className="pontos-box">344</div>
                    <div className="posicao-box">11º</div>
                    </Col>
                </Row>
                <Row className="card-ranking-item card-item-ranking-1">
                    <Col className="d-flex justify-content-around">
                    <div>
                        <img src={user}/>
                    </div>
                    <div>Marcel Santos</div>
                    </Col>
                    <Col className="d-flex justify-content-around">
                    <div className="pontos-box">344</div>
                    <div className="posicao-box">2</div>
                    </Col>
                </Row>
                <Row className="card-ranking-item card-item-ranking-1">
                    <Col className="d-flex justify-content-around">
                    <div>
                        <img src={user}/>
                    </div>
                    <div>Marcel Santos</div>
                    </Col>
                    <Col className="d-flex justify-content-around">
                    <div className="pontos-box">344</div>
                    <div className="posicao-box">2</div>
                    </Col>
                </Row>
                <Row className="card-ranking-item card-item-ranking-1">
                    <Col className="d-flex justify-content-around">
                    <div>
                        <img src={user}/>
                    </div>
                    <div>Marcel Santos</div>
                    </Col>
                    <Col className="d-flex justify-content-around">
                    <div className="pontos-box">344</div>
                    <div className="posicao-box">2</div>
                    </Col>
                </Row>
                <Row className="card-ranking-item card-item-ranking-1">
                    <Col className="d-flex justify-content-around">
                    <div>
                        <img src={user}/>
                    </div>
                    <div>Marcel Santos</div>
                    </Col>
                    <Col className="d-flex justify-content-around">
                    <div className="pontos-box">344</div>
                    <div className="posicao-box">2</div>
                    </Col>
                </Row>
                <Row className="card-ranking-item card-item-ranking-1">
                    <Col className="d-flex justify-content-around">
                    <div>
                        <img src={user}/>
                    </div>
                    <div>Marcel Santos</div>
                    </Col>
                    <Col className="d-flex justify-content-around">
                    <div className="pontos-box">344</div>
                    <div className="posicao-box">2</div>
                    </Col>
                </Row>
            </div>

            </Container>
        </StRanking>
    )
}