import styled from 'styled-components'



export const StHome = styled.section`
        margin-top: 80px;


        .title_first_section_home {
                color:${props => props.theme.colors.primary};
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                display: flex;
                align-items: center;
        }
        p.title-local-camp {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                text-align: center;

                /* Cor – Texto Corrido */

                color: #282828;
        }
        .date-hour {
                background-color: ${props => props.theme.colors.primary};
                border-radius: 4px;
                padding:5px;
        }
        .card-proximos-jogos {
                background-color: ${props => props.theme.colors.cinza1};
                border-radius: 4px;
                padding:5px;
                text-align: center;
        }
        span.hour-card-proximos-jogos {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 8px;
                line-height: 10px;
                display: flex;
                align-items: center;
                text-align: center;

                color: #FFFFFF;
        }
        .text-center {
                text-align: center;
        }


        .ultimos-placares {
                background-color: ${props => props.theme.colors.primary} ;
                margin: 0 4px;
                padding: 15px;
                border-radius: 4px;
        }
        .ultimos-placares .local-data {
                
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 9px;
                line-height: 10px;
                /* identical to box height */

                display: flex;
                align-items: center;

                color: #FFFFFF;
        }
        .time-1 {
                padding: 10px 0;
        }
        .time-1 , .time-2 {
                font-family: 'Lato';
                font-size: 10px;
               
        }
        .placarNumRight {
                text-align: right;
        }
        span.link-main {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;

                color: ${props => props.theme.colors.primary};
        }
        span.play-now {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #282828;
        }

        @media(min-width:992px){
        .date-hour {
                margin-right: 6px;
        }
        }
        @media(max-width:991px){
                .ultimos-placares {
                        margin: 5px 0;
                }
                .video-aliviverde-box {
                        margin-bottom: 60px;
                }
        }
        .time-lado-casa, .time-lado-visitante {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;

                color: #282828;
        }
        .x {
                color: ${props => props.theme.colors.primary} ;
                display: flex;
                justify-content: center;
        }
        .carousel-ultimos-placares .owl-carousel .owl-item img {
                width: unset;
        }
        `