import React, {useState} from "react";

import Dados from './Dados';
import Regulameno from "./Regulamento";


function Form(){
    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState({
        email: '',
        password:''
    })
    const FormTitles = ['Entre para o time!', 'Regulamento'];

    const [emailError, setEmailError] = useState('')

    const PageDisplay = () => {
        if(page === 0) {
            return <Dados formData={formData} setFormData={setFormData} error={emailError} />;
        }else if(page === 1){
            return <Regulameno /> 
        }
    }


    function handleSubmit(){
        if(formData.email === ''){
            setEmailError('email obrigatório')
            return;
        }else{
            setPage((currPage) => currPage + 1)
        }
    }

    return(
        <>
            <h5>{FormTitles[page]}</h5>
            {PageDisplay()}
            <div className="btn-go">
            <button 
           
            onClick={()=> {
                if(page === FormTitles.length - 1){
                    
                    console.log(formData)
                }else{
                    handleSubmit();
                    
                }
            }}>
                {page === FormTitles.length - 1  ? 'Enviar' : 'Próximo'}
            </button>
            </div>

            <div className="back-login">
            <p><a href="/login">Voltar para o login</a></p>
            </div>
            <div className="required-text-one">
            <p>*Dúvidas? Escreva para:</p>
            <p><a href="#">atendimento@bolaoalviverde.com.br</a></p>

            </div>
        </>
    )
}
export default Form