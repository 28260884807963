import styled from 'styled-components'

export const StContainerPublic = styled.div`
    background-color: #fff;
    height:100vh;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;


    @media(min-width:992px){

        border-left: 200px solid ${props => props.theme.colors.primary};
        border-right: 200px solid ${props => props.theme.colors.primary};
    }
    @media(max-width:991px){

        border-left: 20px solid ${props => props.theme.colors.primary};
        border-right: 20px solid ${props => props.theme.colors.primary};
    }

    .body-section {
        overflow:auto;
    }
    h5 {
        margin:0;
        padding:0 ;
        text-align:center;
        color: ${props => props.theme.colors.primary};
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 21px;
    }
    .body-section{
        flex: 1;
        
    }
    .login-inputs div {
        width: 80%;
        overflow: hidden;
    }
    .links-section p{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;

        color: #282828;
    }
    .links-section p a {
        font-weight: bold;
        color: #000;
    }
    .login-inputs input {
        margin: 10px 0;
        width:100% ;
        border:none;
        border-bottom: 1px solid #000;
        background-color: transparent;
        outline: none;
        padding:20px 0%;
    }
    .login-inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    label{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;

        /* Cor – Texto Corrido */
 
        color: #282828;
    }
    .btn-go {
        margin:30px 0 ;
        text-align:center;
    } 
    .btn-go button {
        background-color: ${props => props.theme.colors.primary};
        padding: 15px 90px;
        border: none;
        border-radius: 5px;

        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 15.2924px;

        color: #FFFFFF;
        cursor: pointer;
    }
    .required-text-one {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align:center;

        color: #282828;
    }
    .required-text-one a {
        color:${props => props.theme.colors.primary} ;
    }
    .btn-google {
        cursor: pointer;
    }
    .btn-google button {
        border: none;
        background: transparent;
        margin: 0;
        padding: 0;
        cursor: pointer;
    }


    @media(min-width:992px){
        .header-secundary {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .box-public-screen {
            border-left: 200px solid ${props => props.theme.colors.primary};
            border-right: 200px solid ${props => props.theme.colors.primary};
        }

        .body-section h5 {


            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 43px;
            text-align: center;

            /* Verde Palmeiras – 01 */

            color: #006437;

        }
    }
        .msgs-error-validate {
        color: red;
        font-size: 12px;
    }

`