import styled from 'styled-components'



export const StModalDeleteUser = styled.section`
    position: fixed;
    background-color: #000000b5;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;

    .ui-modal {
        width: 337px;
        max-width: 100%;
        /* height: 337px; */
        text-align: center;
        background-color: #fff;
        border: none;
        padding: 0;
        color: #fff;
        position: relative;
        border-radius: 10px;
        
    } 
    
    button.btn-close-1 {
        background: none;
        border:none;
    }

    .modal-title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: center;

        color: #000000;
    }
    .modal-text {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;

        color: #000000;
    }

    .btn-close-2 {
        border:1px solid #707170;
        border-radius: 4px;
        background: none;
        padding:10px 20px;

    }

    .btn-deletar {
        background-color: #E74C3C;
        border-radius: 4px;
        border:none;

        padding:10px 30px;

        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 15.4932px;

        color: #FFFFFF;
    }


`