import React, { useState } from 'react'
import {Container, Row, Col } from 'react-bootstrap'

import { StContainerPontos } from "./style"

import MenuTop from '../../components/MenuTop/MenuTop'
import MenuBottom from '../../components/MenuBottom/MenuBottom'

import CardAnuncio from '../../components/CardAnuncio/CardAnuncio'
import PontosBlock from '../../components/Pontos/PontosBlock'


import startIcon from '../../assets/images/star.svg'
import MenuDesktop from '../../components/MenuDesktop/MenuDesktop'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop';

export default function Ranking(){

    return(
        <>

            <MenuTop />
            <MenuDesktop />


            <StContainerPontos>
                <CardAnuncio icon={startIcon} title="Pontos"/> 
            </StContainerPontos>

           <PontosBlock />

            <MenuBottom />
            <FooterDesktop />

        </>
    )
}

