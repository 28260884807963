import { StPontos } from "./style"
import {Container, Row, Col } from 'react-bootstrap'

import palmeiras from '../../assets/images/palmeiras.svg'
import xvpiracicaba from '../../assets/images/xv_piracicaba.svg'

export default function PontosBlock(){
    return(

        <StPontos>
            <Container style={{paddingBottom:'80px'}}>
            <Container>
                <Row className="card-pontos-item-titles">
                    <Col className="d-flex" xs={10}>
                        <Col className="">
                            <span>PLACAR OFICIAL</span>
                        </Col>
                        <Col className="">
                            <span>SEU PALPITE</span>
                        </Col>
                    </Col>
                    <Col className="" xs={2}>
                        <span>PONTOS</span>
                    </Col>
                </Row>
            </Container>
            
            <Container>
                <Row className="card-pontos-item">
                    <Col className="d-flex cinza1-box" xs={10}>
                        <Col className="placar-oficial">
                            <img src={palmeiras} />
                            <span>2 x 0</span>
                            <img src={xvpiracicaba}/>
                        </Col>
                        <Col className="seu-palpite">
                            <img src={palmeiras} />
                            <span>2 x 0</span>
                            <img src={xvpiracicaba}/>
                        </Col>
                    </Col>
                    <Col className="seus-pontos">
                        <span>10</span>
                    </Col>
                </Row>
                <Row className="card-pontos-item">
                    <Col className="d-flex cinza1-box" xs={10}>
                        <Col className="placar-oficial">
                            <img src={palmeiras} />
                            <span>2 x 0</span>
                            <img src={xvpiracicaba}/>
                        </Col>
                        <Col className="seu-palpite">
                            <img src={palmeiras} />
                            <span>2 x 0</span>
                            <img src={xvpiracicaba}/>
                        </Col>
                    </Col>
                    <Col className="seus-pontos">
                        <span>10</span>
                    </Col>
                </Row>
                <Row className="card-pontos-item">
                    <Col className="d-flex cinza1-box" xs={10}>
                        <Col className="placar-oficial">
                            <img src={palmeiras} />
                            <span>2 x 0</span>
                            <img src={xvpiracicaba}/>
                        </Col>
                        <Col className="seu-palpite">
                            <img src={palmeiras} />
                            <span>2 x 0</span>
                            <img src={xvpiracicaba}/>
                        </Col>
                    </Col>
                    <Col className="seus-pontos">
                        <span>10</span>
                    </Col>
                </Row>
                <Row className="card-pontos-item">
                    <Col className="d-flex cinza1-box" xs={10}>
                        <Col className="placar-oficial">
                            <img src={palmeiras} />
                            <span>2 x 0</span>
                            <img src={xvpiracicaba}/>
                        </Col>
                        <Col className="seu-palpite">
                            <img src={palmeiras} />
                            <span>2 x 0</span>
                            <img src={xvpiracicaba}/>
                        </Col>
                    </Col>
                    <Col className="seus-pontos">
                        <span>10</span>
                    </Col>
                </Row>
            </Container>

            </Container>
        </StPontos>
    )
}