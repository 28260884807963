import React, { useEffect, useState } from 'react'
import {Container, Row, Col, Card, Table } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'

import { StContainerCampeonatos } from "./style"

import MenuTop from '../../components/MenuTop/MenuTop'
import MenuBottom from '../../components/MenuBottom/MenuBottom'

import api from "../../services/api"

import trofeuVerde from '../../assets/images/winverde.svg';

import brasileiro from '../../assets/images/brasileiro.svg';
import brasileiroDesktop from '../../assets/images/brasileirao.png';
import paulista from '../../assets/images/paulista.svg';
import paulistaDesktop from '../../assets/images/paulistao.png';

import MenuDesktop from '../../components/MenuDesktop/MenuDesktop'

import FooterDesktop from '../../components/FooterDesktop/FooterDesktop';

export default function Campeonatos(){
    const [standingsList, setStandingslist] = useState([])
    const [standingsShow, setStandingsShow] = useState([])

    useEffect(()=> {
        getStandingsList();
        getStandingsShowBrasileirao();
    }, [])

    async function getStandingsList(){

        try{
            const response = await api.get('/api/v1/standings?search=');
            setStandingslist(response.data.data.data);
        }catch(e){
            
        }
        console.log("campeonatos", standingsList)
        
    }

    async function getStandingsShowBrasileirao(){

        try{
            const response = await api.get('/api/v1/standings/71/2022');
            setStandingsShow(response.data.standings[0]);
        }catch(e){
            
        }
        
    }
    console.log("tabela", standingsShow)

    return(
        <>

            <MenuTop />
            <MenuDesktop />


            <StContainerCampeonatos>

                <Container fluid>
                    <Container>
                            <Row>
                                <Col xs={1}><img src={trofeuVerde} style={{height:'20px'}}/></Col>
                                <Col xs={11} className="text-left"><span className="campeonatos-title">Campeonatos</span></Col>
                            </Row>
                            <Row>
                                <Col xs={1}></Col>
                                <Col xs={11} className="text-left"><span className="campeonatos-desc">Confira abaixo a classificação do time nos campeonatos em que ele está participando. </span></Col>
                            </Row>

                            <Row>
                                <Col xs={1}>
                                    
                                </Col>
                                <Col className="pb-5" lg={{offset:0, span:9}}>
                                <Accordion defaultActiveKey="0">


                                
                              
                                        
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="0" className={`brasileiro`}>
                                                <img src={brasileiro} className="img-fluid d-lg-none" width="100%" />
                                                <img src={brasileiroDesktop} className="d-none d-lg-block" width="100%" />
                                                
                                                
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                            <Table hover>
                                            <thead>
                                                <tr>
                                                <th></th>
                                                <th>Clube</th>
                                                <th>PTS</th>
                                                <th>PJ</th>
                                                <th>VIT</th>
                                                <th>E</th>
                                                <th>DER</th>
                                                <th>GP</th>
                                                <th>GC</th>
                                                <th>SG</th>
                                                <th>Últimas cinco</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {standingsShow?.map((itemTabela, indexTabela) => {
                                                    return(
                                                        <>
                                                        <tr className={`${itemTabela.team.id === 121 ? 'meu-time' : ''}`}>
                                                        <td>{indexTabela + 1}</td>
                                                        <td>{itemTabela?.team?.name}</td>
                                                        <td>{itemTabela.points}</td>
                                                        <td>{itemTabela.all.played}</td>
                                                        <td>{itemTabela.all.win}</td>
                                                        <td>{itemTabela.all.draw}</td>
                                                        <td>{itemTabela.all.lose}</td>
                                                        <td>{itemTabela.all.goals.for}</td>
                                                        <td>{itemTabela.all.goals.against}</td>
                                                        <td>{itemTabela.goalsDiff}</td>
                                                        <td>
                                                            <span>1</span>
                                                            <span>2</span>
                                                            <span>3</span>
                                                            <span>4</span>
                                                            <span>5</span>
                                                        </td>
                                                        </tr>
                                                        
                                                        </>
                                                    )
                                                })}

                                            </tbody>
                                            </Table>
                                            </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                
                                {/* <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0" className="brasileiro">
                                        <img src={brasileiro} className="img-fluid d-lg-none" width="100%" />
                                        <img src={brasileiroDesktop} className="d-none d-lg-block" width="100%" />
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                    <Table hover>
                                    <thead>
                                        <tr>
                                        <th></th>
                                        <th>Clube</th>
                                        <th>PTS</th>
                                        <th>PJ</th>
                                        <th>VIT</th>
                                        <th>E</th>
                                        <th>DER</th>
                                        <th>GP</th>
                                        <th>GC</th>
                                        <th>SG</th>
                                        <th>Últimas cinco</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="meu-time">
                                        <td>1</td>
                                        <td>Palmeiras</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>4</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>
                                            <span>1</span>
                                            <span>2</span>
                                            <span>3</span>
                                            <span>4</span>
                                            <span>5</span>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>2</td>
                                        <td>Flamengo</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>4</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>
                                            <span>1</span>
                                            <span>2</span>
                                            <span>3</span>
                                            <span>4</span>
                                            <span>5</span>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>3</td>
                                        <td>Vitória</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>4</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>
                                            <span>1</span>
                                            <span>2</span>
                                            <span>3</span>
                                            <span>4</span>
                                            <span>5</span>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>3</td>
                                        <td>Vitória</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>4</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>
                                            <span>1</span>
                                            <span>2</span>
                                            <span>3</span>
                                            <span>4</span>
                                            <span>5</span>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>3</td>
                                        <td>Vitória</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>4</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>
                                            <span>1</span>
                                            <span>2</span>
                                            <span>3</span>
                                            <span>4</span>
                                            <span>5</span>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>3</td>
                                        <td>Vitória</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>4</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>10</td>
                                        <td>
                                            <span>1</span>
                                            <span>2</span>
                                            <span>3</span>
                                            <span>4</span>
                                            <span>5</span>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </Table>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card> */}
                                



                                </Accordion>
                                </Col>
                            </Row>
                    </Container>    
                </Container>    
            
            </StContainerCampeonatos>


            <MenuBottom />
            <FooterDesktop />


        </>
    )
}

