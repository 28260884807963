export default {
    title: 'Verde',
    colors: {
        primary: '#006437',
        secundary: '#87BD56',
        background: '#006437',
        text: '#fff',
        cinza1:'#D4D5D4',
        cinza2:'#e6e9e6'
    },
}