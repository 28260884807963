import { StPalpite } from "./style"
import {Container, Row, Col } from 'react-bootstrap'

import MenuTop from '../../components/MenuTop/MenuTop'
import MenuBottom from '../../components/MenuBottom/MenuBottom'
import CardAnuncio from '../../components/CardAnuncio/CardAnuncio'


import calendar from '../../assets/images/calendar.svg'
import local from '../../assets/images/local.svg'
import palmeiras from '../../assets/images/palmeiras.svg'
import xvpiracicaba from '../../assets/images/xv_piracicaba.svg'


import BallIcon from '../../assets/images/ball.svg'
import MenuDesktop from "../MenuDesktop/MenuDesktop"
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop';


export default function Palpite(){
    return(
        <>
        <MenuTop />
        <MenuDesktop />
        
        <StPalpite>
        <CardAnuncio icon={BallIcon} title="Dê seu palpite"/> 
            <Container   style={{paddingBottom:'80px'}}>


                <Row xs={12} lg={8} className="d-flex align-items-center justify-content-around pb-5 pt-5">

                <Col xs={12} lg={5} className="card-palpite">
                    <Row className="palpite-card-info">
                        <Col xs={4}><img width="14px" src={calendar} />  <span>24/05 às 18h30</span></Col>
                        <Col xs={8}><img  width="14px"  src={local} /> Campeonato Brasileiro - Allianz Parque</Col>
                    </Row>
                    <Row className="card-flex-palpite" style={{position:'relative', background:'#D4D5D4'}}><span className="versus">X</span>
                        <Col className="lado-verde" xs={6}>
                            <Row>
                                <Col className="lado-mandante" xs={8}>
                                <img src={palmeiras} /> Palmeiras
                                </Col>
                                <Col xs={4} className="box-input-verde">
                                    <input type="number" />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="lado-cinza" xs={6}>
                        <Row>
                                <Col xs={4} className="box-input-cinza">
                                    <input type="number"/>
                                </Col>
                                <Col className="lado-visitante col-time-2" xs={8}>
                                XV Piracicaba
                                <img src={xvpiracicaba} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>


               
                <Col xs={12} lg={5} className="card-palpite">
                    <Row className="palpite-card-info">
                        <Col xs={4}><img width="14px" src={calendar} />  <span>24/05 às 18h30</span></Col>
                        <Col xs={8}><img  width="14px"  src={local} /> Campeonato Brasileiro - Allianz Parque</Col>
                    </Row>
                    <Row className="card-flex-palpite" style={{position:'relative', background:'#D4D5D4'}}><span className="versus">X</span>
                        <Col className="lado-verde" xs={6}>
                            <Row>
                                <Col className="lado-mandante" xs={8}>
                                <img src={palmeiras} /> Palmeiras
                                </Col>
                                <Col xs={4} className="box-input-verde">
                                    <input type="number" />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="lado-cinza" xs={6}>
                        <Row>
                                <Col xs={4} className="box-input-cinza">
                                    <input type="number"/>
                                </Col>
                                <Col className="lado-visitante col-time-2" xs={8}>
                                XV Piracicaba
                                <img src={xvpiracicaba} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} lg={5} className="card-palpite">
                    <Row className="palpite-card-info">
                        <Col xs={4}><img width="14px" src={calendar} />  <span>24/05 às 18h30</span></Col>
                        <Col xs={8}><img  width="14px"  src={local} /> Campeonato Brasileiro - Allianz Parque</Col>
                    </Row>
                    <Row className="card-flex-palpite" style={{position:'relative', background:'#D4D5D4'}}><span className="versus">X</span>
                        <Col className="lado-verde" xs={6}>
                            <Row>
                                <Col className="lado-mandante" xs={8}>
                                <img src={palmeiras} /> Palmeiras
                                </Col>
                                <Col xs={4} className="box-input-verde">
                                    <input type="number" />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="lado-cinza" xs={6}>
                        <Row>
                                <Col xs={4} className="box-input-cinza">
                                    <input type="number"/>
                                </Col>
                                <Col className="lado-visitante col-time-2" xs={8}>
                                XV Piracicaba
                                <img src={xvpiracicaba} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>


                <Col xs={12} lg={5} className="card-palpite">
                    <Row className="palpite-card-info">
                        <Col xs={4}><img width="14px" src={calendar} />  <span>24/05 às 18h30</span></Col>
                        <Col xs={8}><img  width="14px"  src={local} /> Campeonato Brasileiro - Allianz Parque</Col>
                    </Row>
                    <Row className="card-flex-palpite" style={{position:'relative', background:'#D4D5D4'}}><span className="versus">X</span>
                        <Col className="lado-verde" xs={6}>
                            <Row>
                                <Col className="lado-mandante" xs={8}>
                                <img src={palmeiras} /> Palmeiras
                                </Col>
                                <Col xs={4} className="box-input-verde">
                                    <input type="number" />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="lado-cinza" xs={6}>
                        <Row>
                                <Col xs={4} className="box-input-cinza">
                                    <input type="number"/>
                                </Col>
                                <Col className="lado-visitante col-time-2" xs={8}>
                                XV Piracicaba
                                <img src={xvpiracicaba} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} lg={5} className="card-palpite">
                    <Row className="palpite-card-info">
                        <Col xs={4}><img width="14px" src={calendar} />  <span>24/05 às 18h30</span></Col>
                        <Col xs={8}><img  width="14px"  src={local} /> Campeonato Brasileiro - Allianz Parque</Col>
                    </Row>
                    <Row className="card-flex-palpite" style={{position:'relative', background:'#D4D5D4'}}><span className="versus">X</span>
                        <Col className="lado-verde" xs={6}>
                            <Row>
                                <Col className="lado-mandante" xs={8}>
                                <img src={palmeiras} /> Palmeiras
                                </Col>
                                <Col xs={4} className="box-input-verde">
                                    <input type="number" />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="lado-cinza" xs={6}>
                        <Row>
                                <Col xs={4} className="box-input-cinza">
                                    <input type="number"/>
                                </Col>
                                <Col className="lado-visitante col-time-2" xs={8}>
                                XV Piracicaba
                                <img src={xvpiracicaba} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>


                <Col xs={12} lg={5} className="card-palpite">
                    <Row className="palpite-card-info">
                        <Col xs={4}><img width="14px" src={calendar} />  <span>24/05 às 18h30</span></Col>
                        <Col xs={8}><img  width="14px"  src={local} /> Campeonato Brasileiro - Allianz Parque</Col>
                    </Row>
                    <Row className="card-flex-palpite" style={{position:'relative', background:'#D4D5D4'}}><span className="versus">X</span>
                        <Col className="lado-verde" xs={6}>
                            <Row>
                                <Col className="lado-mandante" xs={8}>
                                <img src={palmeiras} /> Palmeiras
                                </Col>
                                <Col xs={4} className="box-input-verde">
                                    <input type="number" />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="lado-cinza" xs={6}>
                        <Row>
                                <Col xs={4} className="box-input-cinza">
                                    <input type="number"/>
                                </Col>
                                <Col className="lado-visitante col-time-2" xs={8}>
                                XV Piracicaba
                                <img src={xvpiracicaba} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>


                </Row>



            </Container>
        </StPalpite>
        <MenuBottom />
        <FooterDesktop />
        </>
    )
}