import React, { useState } from 'react'
import {Container, Row, Col } from 'react-bootstrap'

import { StCotainerMain } from "./style"

import MenuTop from '../../components/MenuTop/MenuTop'
import MenuBottom from '../../components/MenuBottom/MenuBottom'


import Palpite from '../../components/Palpite/Palpite'

import BallIcon from '../../assets/images/ball.svg'

import MenuDesktop from '../../components/MenuDesktop/MenuDesktop';

import FooterDesktop from '../../components/FooterDesktop/FooterDesktop';
import HomeComponent from '../../components/Home/Home'

import userAvatar from '../../assets/images/avatar.png'
import rankingIcon from '../../assets/images/graph.svg'

import CardAnuncio from '../../components/CardAnuncio/CardAnuncio'

import noAvatar from '../../assets/images/noavatar.jpg'

export default function Home(){

    return(
        <>
            
            <MenuTop  />
            <MenuDesktop />


            
            <StCotainerMain>    
                <Container fluid className="home-green-container pt-5 pb-4">
                <div className="d-lg-none d-flex">
                <div className="box-ranking-position mobile d-flex">
                    
                    <Col className="ranking-info d-flex align-items-center justify-content-center">
                        <img src={rankingIcon} alt="Ranking Information" style={{width:'12px', marginRight:'4px'}}/>
                        <span className="ranking-info-description">Ranking</span>
                    </Col>
                    <Col className="position-info d-flex align-items-center justify-content-center">
                        <span>27º</span>
                    </Col>
                </div>
                <div lg={7} xs={12}  className="media">
                    {/* <img src={localStorage.getItem('avatar')} style={{width:'35px', borderRadius:'50%'}} className="mr-3" alt="..." /> */}
                    <img src={noAvatar} style={{width:'35px', borderRadius:'50%'}} className="mr-3" alt="..." />
                    <div className="media-body media-body-mobile text-left">
                        {/* <h5 className="mt-0 user-hello">Olá, {localStorage.getItem('name')}</h5> */}
                        <h5 className="mt-0 user-hello">Olá</h5>
                        <span className="user-welcome">Bem-vindo(a)!</span>
                    </div>
                </div>
                </div>
                    <Container>
                        <Row className="d-flex align-items-center justify-content-around box-slide-premiacao">
                            <Col lg={6}>
                                <Row className="d-none d-lg-flex">                                
                                    <Col lg={7}  className="media pl-5">
                                        {localStorage.getItem('avatar') ? (<>
                                            <img src={localStorage.getItem('avatar')} style={{width:'75px', borderRadius:'50%'}} className="mr-3" alt="..." />
                                        </> ):
                                         (<>
                                            <img src={noAvatar} className="mr-3"  style={{width:'75px', borderRadius:'50%'}} alt="..." />
                                         </>)}
                                        
                                        <div className="media-body media-body-desktop text-left">
                                            <h5 className="mt-0 user-hello">Olá, {localStorage.getItem('name')}</h5>
                                            <span className="user-welcome">Bem-vindo(a)!</span>
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                    <Row className="box-ranking-position">
                                        
                                        <Col className="ranking-info d-flex align-items-center justify-content-center">
                                            <img src={rankingIcon} alt="Ranking Information" style={{width:'12px', marginRight:'4px'}}/>
                                            <span className="ranking-info-description">Ranking</span>
                                        </Col>
                                        <Col className="position-info d-flex align-items-center justify-content-center">
                                            <span>27º</span>
                                        </Col>
                                    </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="box-anuncio-out">
                                        <CardAnuncio widthBox={12}  icon={userAvatar} title="mr"/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={4} style={{background: '#fff', height: '200px'}} className="premio-da-vez mt-4">Premio da vez</Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid>
                    <Container>
                         <HomeComponent />            
                    </Container>
                </Container>
            </StCotainerMain>

            <MenuBottom />
            <FooterDesktop />

        </>
    )
}

