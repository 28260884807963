
import './App.css';
import { AppRoute } from './routes';
import GlobalStyle from './styles/global';

import {ThemeProvider} from 'styled-components';
import verde from './styles/themes/verde';

function App() {
  return (
    <ThemeProvider theme={verde}>
    <GlobalStyle />
        <AppRoute />
    </ThemeProvider>
  );
}

export default App;
