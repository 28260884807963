import styled from 'styled-components'



export const StContainerPerfil = styled.section`
    margin-top: 120px;
    color: #000;


label {
   cursor: pointer;
   /* Style as you please, it will become the visible UI component. */
}

#upload-photo {
        opacity: 0;
        position: absolute;
        z-index: -1;
}
span.text-upload {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        padding:6px 0;
        /* or 150% */


        color: #7A7A7A;
}
#upload-photo {
        width: 100%;
}
h5.perfil-title {
        /* H4 */

        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;

        /* Verde Palmeiras – 01 */

        color: ${props => props.theme.colors.primary};
        margin-top: 10px;
        margin-bottom: 30px;
}
form {
        width: 100%;
}
.form-group input {
        width: 100%;
}
.alterar-senha-text a {
        color:#000 ;
}
.alterar-senha-text {
        padding:0;
}
.form-group {
        margin-top:20px ;
        padding: 5px 0px;
}
.btn-box {
        margin-top: 40px;
        margin-bottom: 80px;
}
button.btn-perfil {
        background-color: ${props => props.theme.colors.primary};
        color: #fff;
        width: 100%;
        padding: 15px;
        border-radius: 10px;
}
button.btn-exclui-conta {
        background-color: transparent;
        border:none !important;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;


        display: flex;
        align-items: center;

        /* Reprovado */

        color: #E74C3C;
}

@media(min-width:992px){
        .fix-desktop-dados {
                margin-top:30px ;
        }
        .alterar-senha-text {
                padding-left: 8px;
        }
}

@media(max-width: 991px){
        .box-border-mobile {
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                padding:20px 0;
        }
}
.edit-password span a {
        color: ${props => props.theme.colors.secundary};
}


`