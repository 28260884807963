import styled from 'styled-components'



export const StContainerVideos = styled.section`
        margin-top: 80px;
        padding-bottom: 80px;

        .container-title-desc h4 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 36px;
            display: flex;
            align-items: center;

            /* Verde Palmeiras – 01 */

            color: ${props => props.theme.colors.primary};
        }
        .container-title-desc h6 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;

            /* Cor – Texto Corrido */

            color: #282828;
        }
        .container-title-desc span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;

            /* Verde Palmeiras – 01 */

            color: ${props => props.theme.colors.primary};
        }
        .card-videos {
            /* background-color: red; */
        }
        span.video-title {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 20.0302px;
            line-height: 24px;

            /* Verde Palmeiras – 01 */

            color: ${props => props.theme.colors.primary};
        }
        span.play-now2 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 16.0242px;
            line-height: 19px;
            display: flex;
            align-items: center;

            /* Cor – Texto Corrido */

            color: #282828;
        }
`