import { StContainerPublicCadastroDireto } from "./style"

import headerLogo from '../../assets/images/header.svg'
import logo from '../../assets/images/logo.svg'

import Form from '../../components/FormCadastro/Form'
import { useState } from "react"
import {useForm} from 'react-hook-form'
import { useEffect } from "react"
import api from "../../services/api"


import Swal from 'sweetalert2'

import {Container, Row, Col } from 'react-bootstrap'

export default function Cadastro(){
    const {watch, register, handleSubmit, setValue, formState:{errors, isSubmitting, isValid}} = useForm({mode:'all'});
    const [formStep, setFormStep] = useState(0)

    const [google_idData, setGoogle_idData] = useState()

    const queryParams = new URLSearchParams(window.location.search)
    const google_id = queryParams.get("id")
    const email_google = queryParams.get("email")
    const name_google = queryParams.get("name")

    useEffect(()=> {

    }, [])

    console.log(google_id, email_google)
    

    const completeFormStep = async (data) => {
        console.log('oi')
        const {email, name, password, password_confirmation} = data;

        try {
            const response = await api.post('/api/v1/auth/user/register', {
                name,
                email,
                password,
                password_confirmation,
                regulation:'1',

            })
            console.log('success');
            Swal.fire({
                icon: 'success',
                title: 'Cadastro realizado com sucesso!',
                text:  `Aguarde a aprovação do administrador.`,
              })
        }catch(error) {
            Swal.fire({
                title:error.response.data.title,
                text: `${error.response.data.message ? error.response.data.message : [...error.response.data.error.email]}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                iconColor: 'red',
                })
            console.log(error)
            console.log(...error.response.data.error.email)
        }

    
    } 

    const renderButton = () => {
        if(formStep > 1){
            return undefined
        }else if(formStep === 1){
            return(
                <button
                disabled={!isValid}
                type="submit"
                 >
                    Enviar
                </button>
            )
        }
        else{
            return(
                <button
                disabled={!isValid}
                onClick={()=> {setFormStep(cur => cur + 1)}} >
                    Próximo
                </button>
            )
        }
    }


    return(
       <StContainerPublicCadastroDireto className="box-public-screen">
                <div className="header-secundary">
                    <img width="100%" className="d-lg-none p-3" src={logo} />
                    <img className="d-none d-lg-block p-3" src={logo} />
                </div>
                <div className="body-section">

                <Container>
                    <Row>
                        <Col xs={12} lg={{span:8, offset:2}}>
                        
                                <form onSubmit={handleSubmit(completeFormStep)}>
                                <div className="login-inputs cadastro">
                                    {/* <Form /> */}


                                    {formStep === 0 && <>
                                        <h5>Entre para o time!</h5>

                                        <div className="text-dados-p">
                                            <p>Informe os dados abaixo para fazer o seu cadastro. </p>
                                        </div>
                                        <div className="block-inputs">
                                            <label>Email* <br/>

                                            </label>
                                            <input 
                                                {...register("email", {
                                                    required: "Email Obrigatório",
                                                    pattern: {
                                                    value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                                    message: "Email inválido!"
                                                }
                                                })}
                                               
                                                type="email" name="email" id="email" placeholder="seuemail@tvalviverde.com.br"  />
                                                {errors.email && <span  className="msgs-error-validate">{errors.email.message}</span> }
                                        </div>
                                        <div className="block-inputs">
                                            <label>Nome* <br/>
                                            </label>
                                            <input 
                                            {...register("name", {
                                                required: "Nome obrigatório",
                                            })}
                                         
                                            type="text" id="name" name="name" placeholder="Vitória Ferreira de Souza"  />
                                            {errors.name && <span  className="msgs-error-validate">{errors.name.message}</span> }
                                        </div>
                
                                        <div className="block-inputs">                         
                                            <label>Senha* <br/>
                                            </label>
                                            <input 
                                            {...register("password", {
                                                required: "Senha obrigatória",
                                                minLength:{value:6, message:"Senha curta demais"},
                                                maxLength:{value:16, message:"Senha grande demais"}
                                            })}
                                            type="password" id="password" name="password" placeholder="*****************" />
                                            {errors.password && <span  className="msgs-error-validate">{errors.password.message}</span> }
                                        </div>
                                        <div className="block-inputs">                            
                                            <label>Confirme sua senha* <br/>
                                            </label>
                                            <input 
                                            {...register("password_confirmation", {
                                                required: "Senha obrigatória",
                                                validate: (value) => value === watch('password') || "Ops! As senhas informadas estão diferentes." 
                                            })}
                                            type="password" id="password_confirmation" name="password_confirmation" placeholder="*****************" />
                                            {errors.password_confirmation && <span  className="msgs-error-validate">{errors.password_confirmation.message}</span> }
                                        </div>
                
                                        <div className="info-senha">
                                            <p>Sua senha deve ter no mínimo 8 caracteres e pelo menos uma letra maiúscula, uma letra minúscula,um número e um caractere especial. <br/>
                                            <br />
                                            *Todos os campos são obrigatórios.
                                            </p>
                                        </div>
                                    </>}


                                    {formStep === 1 &&<>
                                        <h5>Regulamento</h5>
                                        <div>
                                            <div className="text-regulamento-p">
                                                <p>Leia o regulamento para concluir o seu cadastro <br /> e acessar o site. </p>
                                            </div>
                                            <div className="regulamento-text">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue amet, sed diam, quis ac habitant velit ultricies. Tellus id eget enim pretium feugiat sed tincidunt donec tempor. Vivamus non vitae sed morbi massa sed pulvinar. Eu, elementum iaculis aliquet vestibulum vel enim eu ullamcorper. Rhoncus scelerisque quisque in massa egestas consectetur augue vitae. Gravida habitasse accumsan etiam aenean scelerisque mi proin libero cras. Et fringilla rhoncus purus gravida interdum viverra integer ipsum et. Donec quam cursus ut enim sit risus orci, est aliquam. Non cursus morbi at ipsum. Elementum sagittis, dignissim amet a dolor vel. Orci mattis mollis magna sed. Commodo, diam aliquet nibh congue. Viverra orci, orci rhoncus eget nisi fermentum. Egestas consectetur augue vitae.  Orci mattis mollis magna sed. Commodo,
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue amet, sed diam, quis ac habitant velit ultricies. Tellus id eget enim pretium feugiat sed tincidunt donec tempor. Vivamus non vitae sed morbi massa sed pulvinar. Eu, elementum iaculis aliquet vestibulum vel enim eu ullamcorper. Rhoncus scelerisque quisque in massa egestas consectetur augue vitae. Gravida habitasse accumsan etiam aenean scelerisque mi proin libero cras. Et fringilla rhoncus purus gravida interdum viverra integer ipsum et. Donec quam cursus ut enim sit risus orci, est aliquam. Non cursus morbi at ipsum. Elementum sagittis, dignissim amet a dolor vel. Orci mattis mollis magna sed. Commodo, diam aliquet nibh congue. Viverra orci, orci rhoncus eget nisi fermentum. Egestas consectetur augue vitae.  Orci mattis mollis magna sed. Commodo,
                                            </div>
                                            <div className="checkbox">
                                                <input 
                                                {...register("regulamentoCheckbox", {
                                                    required: "Obrigatório",
                                                })}
                                                type="checkbox" id="regulamentoCheckbox" name="regulamentoCheckbox"/>
                                                <label for="regulamentoCheckbox">Declaro que li o regulamento completo e estou de acordo com todos os termos.</label>
                                            </div>
                                        </div>
                                        
                                    </>}


                                </div>
                            <div className="btn-go">
                                {renderButton()}
                            </div>
                                </form>
                        </Col>
                    </Row>
                </Container>








            <div className="back-login">
                <p><a href="/login">Voltar para o login</a></p>
            </div>
            <div className="required-text-one">
                <p>*Dúvidas? Escreva para:</p>
                <p><a href="#">atendimento@bolaoalviverde.com.br</a></p>
            </div>
    </div>
                
       </StContainerPublicCadastroDireto>
    )
}