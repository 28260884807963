import { StHome } from "./style";
import {Container, Row, Col } from 'react-bootstrap';
import arrowGreen from '../../assets/images/arrows_green.svg';

import palmeiras from '../../assets/images/palmeiras.svg';
import xvpiracicaba from '../../assets/images/xv_piracicaba.svg';

import brasaoPalmeiras from '../../assets/images/brasao-palmeiras.png';
import brasaoCorinthians from '../../assets/images/corinthians1.png';
import tvalviverde from '../../assets/images/tvalviverde.png';

import OwlCarousel from 'react-owl-carousel2'
import 'react-owl-carousel2/src/owl.theme.default.css'
import 'react-owl-carousel2/lib/styles.css'


export default function HomeComponent(){
    const options = {
		items: 3,
		nav: false,
		autoplay: false,
		dots: true,
		loop: false,
		autoHeight: true,
		animateIn: 'fadeIn',
		animateOut: 'fadeOut',

        responsive:{
            0:{
                items:1,
                nav:false
            },
            600:{
                items:1,
                nav:false
            },
            1000:{
                items:3,
                nav:false,
                loop:false
            }
        }
    };

    return(
        <StHome>
            <Container fluid>
                <Container>
                    <Row>
                        <Col xs={12} lg={6}>
                            <Row className="title_first_section_home">
                                <Col className="p-0">                                
                                    <span>
                                        <img src={arrowGreen} /> Próximas partidas
                                    </span>
                                </Col>
                            </Row>

                            <Row className="mb-4 mt-4">
                                <Col>
                                <Row>
                                    <Col className="text-center">
                                        <p className="title-local-camp">Campeonato Brasileiro - Allianz Parque</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={2} className="date-hour d-flex align-items-center justify-content-center">                                    
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                                            <span>24/07</span>
                                            <span className="hour-card-proximos-jogos">19h30</span>
                                        </div>
                                    </Col>
                                    <Col lg={9} className="card-proximos-jogos d-flex align-items-center justify-content-start">
                                        <Col lg={5} className="time-lado-casa"><img src={palmeiras} alt="Palmeiras" /> Palmeiras</Col>
                                        <Col lg={1} className="x">x</Col>
                                        <Col lg={5} className="time-lado-visitante"><img src={xvpiracicaba} alt="Piracicaba" /> XV Piracicaba </Col>
                                    </Col>
                                </Row>
                                    
                                </Col>
                            </Row>
                                                       <Row className="mb-4 mt-4">
                                <Col>
                                <Row>
                                    <Col className="text-center">
                                        <p className="title-local-camp">Campeonato Brasileiro - Allianz Parque</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={2} className="date-hour d-flex align-items-center justify-content-center">                                    
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                                            <span>24/07</span>
                                            <span className="hour-card-proximos-jogos">19h30</span>
                                        </div>
                                    </Col>
                                    <Col lg={9} className="card-proximos-jogos d-flex align-items-center justify-content-start">
                                        <Col lg={5} className="time-lado-casa"><img src={palmeiras} alt="Palmeiras" /> Palmeiras</Col>
                                        <Col lg={1} className="x">x</Col>
                                        <Col lg={5} className="time-lado-visitante"><img src={xvpiracicaba} alt="Piracicaba" /> XV Piracicaba </Col>
                                    </Col>
                                </Row>
                                    
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Row className="title_first_section_home">
                                    <span>
                                        <img src={arrowGreen} /> Últimos placares
                                    </span>
                            </Row>


                            <Row className="mb-4 mt-4 d-flex justify-content-between carousel-ultimos-placares">

                                <OwlCarousel  options={options} >

                                    <Col xs={12} lg={"auto"} className="ultimos-placares">
                                        <Row className="local-data">
                                            <Col>Brasielirão</Col>
                                            <Col className="placarNumRight">07/05/2022</Col>
                                        </Row>
                                        <Row className="time-1">
                                            <Col className="d-flex"><img src={brasaoPalmeiras} style={{marginRight:'5px'}} /> Palmeiras</Col>
                                            <Col className="placarNumRight">2</Col>
                                        </Row>
                                        <Row className="time-2">
                                            <Col className="d-flex"><img src={brasaoCorinthians} style={{marginRight:'5px'}} /> Corinthians</Col>
                                            <Col className="placarNumRight">0</Col>
                                        </Row>
                                    </Col>






                                    <Col xs={12}  lg={"auto"} className="ultimos-placares">
                                        <Row className="local-data">
                                            <Col>Brasielirão</Col>
                                            <Col className="placarNumRight">07/05/2022</Col>
                                        </Row>
                                        <Row className="time-1">
                                            <Col className="d-flex"><img src={brasaoPalmeiras} style={{marginRight:'5px'}} /> Palmeiras</Col>
                                            <Col className="placarNumRight">2</Col>
                                        </Row>
                                        <Row className="time-2">
                                            <Col className="d-flex"><img src={brasaoCorinthians} style={{marginRight:'5px'}} /> Corinthians</Col>
                                            <Col className="placarNumRight">0</Col>
                                        </Row>
                                    </Col>

                                    <Col xs={12}  lg={"auto"} className="ultimos-placares">
                                        <Row className="local-data">
                                            <Col>Brasielirão</Col>
                                            <Col className="placarNumRight">07/05/2022</Col>
                                        </Row>
                                        <Row className="time-1">
                                            <Col className="d-flex"><img src={brasaoPalmeiras} style={{marginRight:'5px'}} /> Palmeiras</Col>
                                            <Col className="placarNumRight">2</Col>
                                        </Row>
                                        <Row className="time-2">
                                            <Col className="d-flex"><img src={brasaoCorinthians} style={{marginRight:'5px'}} /> Corinthians</Col>
                                            <Col className="placarNumRight">0</Col>
                                        </Row>
                                    </Col>




                                    <Col xs={12}  lg={"auto"} className="ultimos-placares">
                                        <Row className="local-data">
                                            <Col>Brasielirão</Col>
                                            <Col className="placarNumRight">07/05/2022</Col>
                                        </Row>
                                        <Row className="time-1">
                                            <Col className="d-flex"><img src={brasaoPalmeiras} style={{marginRight:'5px'}} /> Palmeiras</Col>
                                            <Col className="placarNumRight">2</Col>
                                        </Row>
                                        <Row className="time-2">
                                            <Col className="d-flex"><img src={brasaoCorinthians} style={{marginRight:'5px'}} /> Corinthians</Col>
                                            <Col className="placarNumRight">0</Col>
                                        </Row>
                                    </Col>
                                </OwlCarousel>
                               
                            </Row>

                            <Row style={{color:'red'}} className="border-top video-aliviverde-box">
                                <Col className="pl-0 pt-5 pb-5">
                                    <img src={tvalviverde} alt="tv alviverde" />
                                </Col>
                                <Col className="d-flex align-items-center">
                                    <a href="#"><span className="link-main">Confira os últimos vídeos do TV Alviverde</span> <br /> <span className="play-now">assista agora</span></a>
                                    
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </StHome>
    )
}