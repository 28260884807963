import React, { useState } from 'react'
import {Container, Row, Col } from 'react-bootstrap'

import { StContainerComoFunciona } from "./style"

import MenuTop from '../../components/MenuTop/MenuTop'
import MenuBottom from '../../components/MenuBottom/MenuBottom'

import qAsk from '../../assets/images/interr.svg'


import palmeiras from '../../assets/images/palmeiras.svg'
import xvpiracicaba from '../../assets/images/xv_piracicaba.svg'
import jogador from '../../assets/images/jogador.png'
import alertIcon from '../../assets/images/alerticon.png'
import starIcon from '../../assets/images/star.svg'
import MenuDesktop from '../../components/MenuDesktop/MenuDesktop'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop';
export default function ComoFunciona(){



    return(
        <>
    
            <MenuTop />
            <MenuDesktop />
            <StContainerComoFunciona>

                    <Row  className="desktop-fixes">
                        <Col xs={12} lg={{span:4, offset:2}}>
                        
                        <Container fluid className='fluid-como-funciona'>
                            <Container className='mb-4'>
                                <Row>
                                    <Col>
                                    <h4 className='como-funciona-titulo'><img src={qAsk} /> Como funciona</h4>
                                    <p className='como-funciona-desc'>Para participar do Bolão, registre os seus palpites para os jogos em até 1h (60 minutos) antes do início de cada partida. </p>
                                    </Col>
                                </Row>
                                <h5 className='como-funciona-strong-text'>Você ganha pontos da seguinte forma:</h5>
                                <Row className='border-bottom'>
                                    <Col xs={4} className='border-right mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                        <span><span className='num-pontos'>10</span><span className='num-pontos-text'> PONTOS</span></span>
                                    </Col>
                                    <Col xs={8} className='mt-4 mb-4 text-left d-flex align-items-center justify-content-start'>
                                        <span className='text-mec'>Acertando o placar exato da partida. </span>
                                    </Col>
                                </Row>

                                <Row className='border-bottom'>
                                    <Col xs={4} className='border-right mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                        <span><span className='num-pontos'>07</span><span className='num-pontos-text'> PONTOS</span></span>
                                    </Col>
                                    <Col xs={8} className='mt-4 mb-4 text-left d-flex align-items-center justify-content-start'>
                                        <span className='text-mec'>Acertando o resultado e o número de gols de um dos times.</span>
                                    </Col>
                                </Row>

                                <Row className='border-bottom'>
                                    <Col xs={4} className='border-right mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                        <span><span className='num-pontos'>05</span><span className='num-pontos-text'> PONTOS</span></span>
                                    </Col>
                                    <Col xs={8} className='mt-4 mb-4 text-left d-flex align-items-center justify-content-start'>
                                        <span className='text-mec'>Acertando o resultado do jogo, mas não o número de gols de um dos times. </span>
                                    </Col>
                                </Row>


                                <Row className='border-bottom'>
                                    <Col xs={4} className='border-right mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                        <span><span className='num-pontos'>02</span><span className='num-pontos-text'> PONTOS</span></span>
                                    </Col>
                                    <Col xs={8} className='mt-4 mb-4 text-left d-flex align-items-center justify-content-start'>
                                        <span className='text-mec'>Acertando o número de gols de um dos times </span>
                                    </Col>
                                </Row>
                                <Row className="jogador-desktop pt-5">
                                    <Col xs={8}>
                                        <h5 className='fique-ligado-title'>Fique ligado</h5>
                                        <p className='fique-ligado-description'>Em caso de empate aos 90 minutos de campeonatos que não são de pontos corridos, será valido o resultado ao fim da prorrogação, desconsiderando uma eventual disputa de pênaltis. </p>
                                    </Col>
                                    <Col xs={4} className="jogador-box d-flex align-items-center justify-content-end p-0">
                                        <img src={jogador} style={{width: '162px', height: '127px', position: 'relative', left:'46px'}} alt="Jogador"/>
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    
                        </Col>




                    
                    <Col xs={12} lg={4}>
                    
                    <Container fluid className='fluid-exemplo'>
                        <Container>
                            <Container>

                            <Row>
                                <Col>
                                <h6 className='exemplo-title'>Veja o exemplo</h6>
                                <p className='exemplo-desc'>O resultado de uma suposta partida foi Palmeiras 2x1 Corinthians. Os palpites abaixo valerão: </p>
                                </Col>
                            </Row>
                            </Container>
                            
                            <Container>

                            <Row className='border-bottom align-items-start d-flex'>
                                {/* <Col xs={4} className=' mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                    <span><span>40</span>PONTOS</span>
                                </Col> */}
                                <Col xs={5} style={{height:'40px'}} className='exmeplo-times-resultado mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                    <img src={palmeiras} /><span className='exmplo-times-texto' style={{fontWeight:'700'}}> 2 x 1 </span> <img src={xvpiracicaba}/>
                                </Col>
                                <Col xs={7} className='mt-4 mb-4 text-left d-flex align-items-center justify-content-start'>
                                    <span className='exmplo-times-texto'><span className='divider-placar' style={{fontWeight:'700'}}>Palmeiras 2x1 Corinthians</span><br />
                                            <span style={{fontWeight:'700'}}><img src={starIcon} /> 10 pontos</span><br />
                                            O participante acertou o o placar exato da partida. </span>
                                </Col>
                            </Row>


                            <Row className='border-bottom align-items-start d-flex'>
                                {/* <Col xs={4} className=' mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                    <span><span>40</span>PONTOS</span>
                                </Col> */}
                                <Col xs={5} style={{height:'40px'}} className='exmeplo-times-resultado mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                    <img src={palmeiras} /><span className='exmplo-times-texto' style={{fontWeight:'700'}}> 3 x 1 </span> <img src={xvpiracicaba}/>
                                </Col>
                                <Col xs={7} className='mt-4 mb-4 text-left d-flex align-items-center justify-content-start'>
                                    <span className='exmplo-times-texto'><span className='divider-placar' style={{fontWeight:'700'}}>Palmeiras 3x1 Corinthians</span><br />
                                            <span style={{fontWeight:'700'}}><img src={starIcon} /> 7 pontos</span><br />
                                            O participante acertou o resultado (vitória do Palmeiras) e ainda a quantidade de gols de um dos times (Corinthians). </span>
                                </Col>
                            </Row>




                            <Row className='border-bottom align-items-start d-flex'>
                                {/* <Col xs={4} className=' mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                    <span><span>40</span>PONTOS</span>
                                </Col> */}
                                <Col xs={5} style={{height:'40px'}} className='exmeplo-times-resultado mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                    <img src={palmeiras} /><span className='exmplo-times-texto' style={{fontWeight:'700'}}> 3 x 2 </span> <img src={xvpiracicaba}/>
                                </Col>
                                <Col xs={7} className='mt-4 mb-4 text-left d-flex align-items-center justify-content-start'>
                                    <span className='exmplo-times-texto'><span className='divider-placar' style={{fontWeight:'700'}}>Palmeiras 1x0 Corinthians</span><br />
                                            <span style={{fontWeight:'700'}}><img src={starIcon} /> 5 pontos</span><br />
                                            O participante acertou o resultado do jogo (vitória do Palmeiras), mas não o placar exato. </span>
                                </Col>
                            </Row>




                            <Row className='border-bottom align-items-start d-flex'>
                                {/* <Col xs={4} className=' mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                    <span><span>40</span>PONTOS</span>
                                </Col> */}
                                <Col xs={5} style={{height:'40px'}} className='exmeplo-times-resultado mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                    <img src={palmeiras} /><span className='exmplo-times-texto' style={{fontWeight:'700'}}> 0 x 1 </span> <img src={xvpiracicaba}/>
                                </Col>
                                <Col xs={7} className='mt-4 mb-4 text-left d-flex align-items-center justify-content-start'>
                                    <span className='exmplo-times-texto'><span className='divider-placar' style={{fontWeight:'700'}}>Palmeiras 0x1 Corinthians</span><br />
                                            <span style={{fontWeight:'700'}}><img src={starIcon} /> 2 pontos</span><br />
                                            O participante acertou apenas a quantidade de gols de um dos times (Corinthians).</span>
                                </Col>
                            </Row>





                            <Row className='border-bottom align-items-start d-flex'>
                                {/* <Col xs={4} className=' mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                    <span><span>40</span>PONTOS</span>
                                </Col> */}
                                <Col xs={5} style={{height:'40px'}} className='exmeplo-times-resultado mt-4 mb-4 text-center d-flex align-items-center justify-content-center'>
                                    <img src={palmeiras} /><span className='exmplo-times-texto' style={{fontWeight:'700'}}> 0 x 1 </span> <img src={xvpiracicaba}/>
                                </Col>
                                <Col xs={7} className='mt-4 mb-4 text-left d-flex align-items-center justify-content-start'>
                                    <span className='exmplo-times-texto'><span className='divider-placar' style={{fontWeight:'700'}}>Palmeiras 0x0 Corinthians</span><br />
                                            <span style={{fontWeight:'700'}}><img src={starIcon} /> 0 pontos</span><br />
                                            O participante errou tudo! </span>
                                </Col>
                            </Row>


                            </Container>
                        </Container>
                            
                    </Container>
                    </Col>
                    </Row>




                    <Container fluid className='fique-ligado-fluid d-lg-none'>
                        <Row>
                            <Col xs={7}>
                                <h5 className='fique-ligado-title'>Fique ligado</h5>
                                <p className='fique-ligado-description'>Em caso de empate aos 90 minutos de campeonatos que não são de pontos corridos, será valido o resultado ao fim da prorrogação, desconsiderando uma eventual disputa de pênaltis. </p>
                            </Col>
                            <Col className="jogador-box d-flex align-items-center justify-content-end p-0" xs={5}>
                                <img src={jogador} style={{width: '162px', height: '127px'}} />
                            </Col>
                        </Row>
                    </Container>

                    <Container fluid className='alert-fluid  d-lg-none'>
                            <Row>
                                <Col xs={2} className='d-flex align-items-center justify-content-start p-0' >
                                    <img src={alertIcon} />
                                </Col>
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <span className='info-description-text'>O participante que não registrar os seus palpites até uma hora antes dos jogos marcará 0 pontos nas partidas não preenchidas.</span>
                                </Col>
                            </Row>
                    </Container>
            </StContainerComoFunciona>

            <MenuBottom />
            <FooterDesktop />                  

        </>
    )
}

