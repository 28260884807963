import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
body {
    margin:0 ;
    padding:0 ;
    /* background: ${props => props.theme.colors.primary}; */
    color: ${props => props.theme.colors.text};
}
.box-login {
    background: ${props => props.theme.colors.primary};
}
.header-secundary {
    background: ${props => props.theme.colors.secundary};
}
label {
    margin: 0;
}
.owl-theme .owl-dots .owl-dot.active span{
    background: ${props => props.theme.colors.secundary};
}


div#root {
    display: flex;
    flex-direction: column;
    
    min-height: 100%;
    height: 100vh;
}
@media(min-width:992px){
  div#root {
    justify-content: space-between;
  }
}

@media(max-width:991px){
  div#root {
    justify-content:unset;
  }
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}











/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey; 
  border-radius: 0px;
  opacity: 5;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc; 
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #999; 
}





.header-secundary,.body-section {
    box-shadow: 0 0 15px 0px #000;
    clip-path: inset(0px -15px 0px -15px);
}


@media(min-width:1600px){

  .body-section {
    display: flex;
      flex-direction: column;
      justify-content: center;
  
  }
}


button.swal2-confirm.swal2-styled{
        background: ${props => props.theme.colors.primary} !important;
    }

`;