import React, { useState } from 'react'
import {Container, Row, Col } from 'react-bootstrap'

import { StContainerRanking } from "./style"

import MenuTop from '../../components/MenuTop/MenuTop'
import MenuBottom from '../../components/MenuBottom/MenuBottom'

import CardAnuncio from '../../components/CardAnuncio/CardAnuncio'
import RankingBlock from '../../components/Ranking/RankingBlock'

import RankingIcon from '../../assets/images/graph.svg'
import MenuDesktop from '../../components/MenuDesktop/MenuDesktop'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'



export default function Ranking(){

    return(
        <>

            <MenuTop />
            <MenuDesktop />


            <StContainerRanking>
                <CardAnuncio icon={RankingIcon} title="Ranking"/> 
            </StContainerRanking>

           <RankingBlock />

            <MenuBottom />
            <FooterDesktop />

        </>
    )
}

