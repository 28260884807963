import styled from 'styled-components'

export const StMenuDesktop = styled.div`
background: ${props => props.theme.colors.secundary };
position: fixed;
width:100%;
display:flex;
flex-wrap: wrap;
height:80px;
text-align:center ;
top:0;
z-index: 9999999;
box-shadow: 0px 1px 8px #695b5b;

.links-desktop a {
    color:#fff;
}
`