import { StContainerPublicRedefinirSenhaEmail } from "./style"

import headerLogo from '../../assets/images/header.svg'
import logo from '../../assets/images/logo.svg'
import {useForm} from 'react-hook-form'
import {Container, Row, Col } from 'react-bootstrap'
import { useState } from "react"
import api from "../../services/api"
import Swal from 'sweetalert2'

export default function RedefinirSenhaEmail(){
    const {register, handleSubmit, formState:{errors, isSubmitting}} = useForm({mode:'all', onBlur:true});

    const [email, setEmail] = useState('');
    const [isSending, setIsSending] = useState(false);

    const handleRecoverPassword = (data) => {
       const {email} = data;
        handleSubmit(async(e)=>{
            setIsSending(true);
            try{
             
                const response = await api.post(`/api/v1/auth/pass/recovery_password`, {
                    email
                })
                console.log('success');
                Swal.fire({
                    icon: 'success',
                    title: 'Recuperação de senha realizada com sucesso!',
                    text:  `Aguarde o envio de um email com as instruções para recuperação de senha.`,
                  })
            }catch(err){
                Swal.fire({
                    icon: 'error',
                    title: err.response.data.title,
                    text:  err.response.data.message,
                  })
                    setIsSending(false);
                  
                  return
                // console.log(err.response.data.error.password[0])
                // console.log(err.response.data.error.taxid[0])
            }
            setIsSending(false);
        
            setEmail('');

        })()
    }

    return(
        <StContainerPublicRedefinirSenhaEmail className="box-public-screen">
        <div className="header-secundary">
                <img width="100%" className="d-lg-none p-3" src={logo} />
                <img className="d-none d-lg-block p-3" src={logo} />
        </div>
        <div className="body-section">
            <h5>Esqueceu sua senha?</h5>
            <p>Informe o e-mail cadastrado para alterar sua senha.</p>

            <Container>
                <Row>
                    <Col xs={12} lg={{span:8, offset:2}}>
                    <form onSubmit={handleSubmit(handleRecoverPassword)}>

                    <div className="login-inputs">
                        <div>
                            <label>Email* </label>
                            <input
                                {...register("email", {
                                    required: "Email Obrigatório",
                                    pattern: {
                                        value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                        message: "Email inválido!"
                                        }
                                    })}
                                type="email" id="email" name="email" class="" />
                                {errors.email && <span  className="msgs-error-validate">{errors.email.message}</span> }
                        </div>

                        <div className="links-section">
                            <p>Ainda não tem cadastro? <a href="/cadastro">Faça aqui</a></p>
                        </div>
                        
                        <div className="btn-go">
                            <button>ENVIAR</button>
                        </div>

                        <div className="required-text-one">
                            <p>*Todos os campos são obrigatórios.</p>
                        </div>
                        
                        <div className="back-login">
                            <p><a href="/login">Voltar para o login</a></p>
                        </div>
                        <div className="required-text-one">
                            <p>*Dúvidas? Escreva para:</p>
                            <p><a href="#">atendimento@bolaoalviverde.com.br</a></p>

                        </div>
                    </div>
                    </form>
                    </Col>
                </Row>
            </Container>

        </div>
</StContainerPublicRedefinirSenhaEmail>
)
}
