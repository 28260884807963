import styled from 'styled-components'


export const StPontos = styled.section`
    width: 100%;
    background-color: #fff;
    color: #000;
    border-radius: 10px;
    padding-top:30px;

    .placar-oficial {
        background-color: ${props => props.theme.colors.primary};
        border-radius: 7px;

        display:flex;
        align-items: center;
        justify-content: center;
    }
    .placar-oficial  span {
        margin: 0 10px;

        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #FFFFFF;
    }
    .seu-palpite {
        background-color: ${props => props.theme.colors.cinza1};
        border-radius: 10px;

        display:flex;
        align-items: center;
        justify-content: center;
    }
    .seu-palpite span {
        margin: 0 10px;

        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000;
    }
    .seus-pontos {
        background-color: ${props => props.theme.colors.secundary};
        border-radius: 7px;

        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content:center ;
        color: #FFFFFF;
    }
    .placar-oficial, .seu-palpite, .seus-pontos {
        padding:14px 0 ;
    }
    .cinza1-box {
        background-color: ${props => props.theme.colors.cinza1};
        padding: 0;
        margin-right: 10px;
        border-radius: 7px;
    }
    .card-pontos-item-titles span {
        font-size: 2vw;
        color:${props => props.theme.colors.primary};
    }
    .card-pontos-item {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`