import styled from 'styled-components'


export const StContainerComoFunciona = styled.section`
    margin-top: 120px;
    color: #000;


    .fluid-como-funciona{
        
    }
    h4.como-funciona-titulo {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;

        color: #006437;
    }

    .como-funciona-desc {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        /* Cor – Texto Corrido */

        color: #282828;
    }
    .como-funciona-strong-text {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;

        /* Cor – Texto Corrido */

        color: #282828;
    }
    .num-pontos {
        /* H3 */

        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;

        /* Verde Palmeiras – 02 */
        color: ${props => props.theme.colors.secundary};
    }
    .num-pontos-text {
                /* H3 */

        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;


        /* Verde Palmeiras – 02 */
        color: ${props => props.theme.colors.secundary};
    }
    .text-mec {
        /* H6 */

        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;

        /* Cor – Texto Corrido */

        color: #282828;
    }
    .exemplo-title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;


        color: #FFFFFF;
    }
    .exemplo-desc {
        /* H6 */

        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;

        color: #FFFFFF;
    }
    .fluid-exemplo {
        background-color: ${props=> props.theme.colors.primary};
        border-radius: 8px;
        padding: 30px 0;
    }

    .exmeplo-times-resultado {
        background-color: ${props => props.theme.colors.secundary};
        border-radius: 5px;
    }
    .exmplo-times-texto {
        font-family: 'Lato';
        font-style: normal;
        color:#FFFFFF;
    }

    .fique-ligado-fluid {
        padding-top: 50px;
        padding-bottom: 20px;
    }
    .fique-ligado-title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: ${props => props.theme.colors.primary}
    }
    .ique-ligado-description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        /* Cor – Texto Corrido */

        color: #282828;

    }

    .alert-fluid {
        background-color: ${props => props.theme.colors.primary} ;
        margin-top: 10px;
        margin-bottom: 60px;
    }

    .info-description-text {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;

        color: #FFFFFF;
    }


    /* MOBILE */
    @media(max-width:991px){
        .jogador-desktop {
            display: none;
        }
    }


    /* DESKTOP */
    @media(min-width:992px){
        .desktop-fixes {
            margin-bottom: 150px;
            margin-top: 30px;
        }
    }

`