import { StContainerPublic } from "./style"
import headerLogo from '../../assets/images/header.svg'
import logo from '../../assets/images/logo.svg'
import btnGoogle from '../../assets/images/btn-google.png'
import api from "../../services/api"
import { useState } from "react"
import { useEffect } from "react"
import {Link, useHistory } from 'react-router-dom'

import Swal from 'sweetalert2'

import {login} from '../../services/auth'
import {useForm} from 'react-hook-form'

import {Container, Row, Col, Spinner } from 'react-bootstrap'


export default function Login(){
    
    const history = useHistory();

    const {register, handleSubmit, formState:{errors, isSubmitting}} = useForm({mode:'all', onBlur:true});
    const [isLoadingLogin, setIsLoadingLogin] = useState(false);
    const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
    const [loginData, setLoginData] = useState()


    useEffect(()=> {
        const queryParams = new URLSearchParams(window.location.search)
        const access_token = queryParams.get("access_token")
        const avatar  = queryParams.get("avatar");
        const name = queryParams.get("name");



        if(access_token) {
            localStorage.setItem("bolaoalviverde-token", access_token)

            localStorage.setItem("avatar", avatar);
            localStorage.setItem("name", name);
            window.location.reload()
        }else{
            return;
        }



    }, [])

    async function handleGoogleLogin(){

        try{
            setIsLoadingGoogle(true)
            const response = await api.get('/api/v1/auth/oauth_google/redirect_provider')
            setLoginData(response.data.url)
            window.location = response.data.url
            
        }catch(e){
            setIsLoadingGoogle(false)
        }
        setIsLoadingGoogle(false)

    }

    async function handleLogin(data){
  
        
        setIsLoadingLogin(true)
        console.log(data)
        const {email, password} = data;
        try{
            const response = await api.post(`/api/v1/auth/login`, {
                email,
                password
            })
            login(response.data.access_token);
            console.log(response.data.access_token)
            history.push("/");
        
        }catch(error){
            setIsLoadingLogin(false)

            Swal.fire({
                title:error.response.data.title,
                text: error.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok',
                iconColor: 'red',
                })
                
            console.log(error)

           
        }finally{
            setIsLoadingLogin(false)
        }

      }


    console.log(loginData)
    return(
       <StContainerPublic className="box-public-screen">
                <div className="header-secundary">
                    <img width="100%" className="d-lg-none p-3" src={logo} />
                    <img className="d-none d-lg-block p-3" src={logo} />
                </div>
                <div className="body-section">
                    <h5>Faça o login e dê <br />seus palpites.</h5>
                    <Container>
                        <Row>
                            <Col xs={12} lg={{span:8, offset:2}}> 

                                <form onSubmit={handleSubmit(handleLogin)}>
                                <div className="login-inputs">
                                    <div>
                                        <label>Email*
                                        </label>
                                        <input 
                                            {...register("email", {
                                                required: "Email Obrigatório",
                                                pattern: {
                                                    value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                                    message: "Email inválido!"
                                                    }
                                                })}
                                            type="email" id="email" name="email"  class="" placeholder="seuemail@tvalviverde.com.br" />
                                             {errors.email && <span  className="msgs-error-validate">{errors.email.message}</span> }
                                    </div>

                                    <div>                            
                                        <label>Senha*</label>
                                            <input 
                                            {...register("password", {
                                                required: "Senha Obrigatória",
                                                })}
                                            type="password" id="password" name="password" placeholder="*****************" />
                                            {errors.password && <span  className="msgs-error-validate">{errors.password.message}</span> }
                                    </div>
                                    <div className="links-section">
                                        <p>Esqueceu a senha? <a href="/redefinir-senha">Clique aqui</a></p>
                                        <p>Ainda não tem cadastro? <a href="/cadastro">Faça aqui</a></p>
                                    </div>
                                    
                                    <div className="btn-google">
                                        <div onClick={handleGoogleLogin} className="d-flex align-items-center">
                                            <img src={btnGoogle} />
                                            {isLoadingGoogle && 
                                            <Spinner animation="border" style={{width:'32px', color:'#ccc'}} role="status">
                                            <span className="visually-hidden"></span>
                                            </Spinner>}
                                        </div>
                                    </div>
                                    <div className="btn-go">
                                        <button type="submit">ENTRAR</button>
                                    </div>

                                    <div className="required-text-one">
                                        <p>*Todos os campos são obrigatórios.</p>
                                    </div>
                                    <div className="required-text-one">
                                        <p>*Dúvidas? Escreva para:</p>
                                        <p><a href="#">atendimento@bolaoalviverde.com.br</a></p>

                                    </div>
                                </div>
                                    </form>
                            </Col>
                        </Row>
                    </Container>

                </div>
       </StContainerPublic>
    )
}