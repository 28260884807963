import styled from 'styled-components'



export const StContainerGanhadores = styled.section`
            margin-top: 80px;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;

        .container-ganhadores-main {
            background-color: ${props => props.theme.colors.primary} ;
            display: flex;
            flex:1;
        }
        .container-title-desc h4 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 36px;
            display: flex;
            align-items: center;

            /* Verde Palmeiras – 01 */

            color: ${props => props.theme.colors.primary};
        }
        .container-title-desc h6 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;

            /* Cor – Texto Corrido */

            color: #282828;
        }
        .container-title-desc span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;

            /* Verde Palmeiras – 01 */

            color: ${props => props.theme.colors.primary};
        }
        .card-videos {
            /* background-color: red; */
        }
        span.ganhador-title {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 24.1091px;
            line-height: 29px;
            display: flex;
            align-items: center;
            text-transform: uppercase;

            color: #FFFFFF;
        }
        span.ganhador-play-now2 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16.0727px;
            line-height: 19px;
            display: flex;
            align-items: center;

            color: #FFFFFF;
        }
`