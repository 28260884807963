import { StFooterDesktop } from "./style"
import {Container, Row, Col, Nav} from 'react-bootstrap'

import headerLogo from '../../assets/images/header.svg'
import logo from '../../assets/images/logo.svg'
import MenuIcon from '../../assets/images/menu.svg'

import userAvatar from '../../assets/images/avatar.png'
import { useState } from "react"

import youtube from '../../assets/images/youtube.svg';
import instagram from '../../assets/images/instagram.svg';
import face from '../../assets/images/face.svg';

export default function FooterDesktop(){
 
    return(
        <StFooterDesktop className="d-none d-lg-flex">
        <Container fluid className=''>
            <Container>
                <Row className="d-flex justify-content-around">
                      <Col lg={5} className='d-flex align-items-center justify-content-start'>
                            <span>Duvidas? <br /> contato@bolaotvalviverde.com.br</span>
                      </Col>
                      <Col lg={5} className='d-flex align-items-center justify-content-end'>
                            <Row>
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <img src={youtube} alt="Facebook" />
                                    <img src={instagram} alt="Instagram" className="ml-2 mr-2" />
                                    <img src={face} alt="Face" />
                                </Col>
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <img src={logo} alt="Logo" />
                                </Col>
                            </Row>
                      </Col>
                </Row>
            </Container>
        </Container>
        </StFooterDesktop>
    )
}