import styled from 'styled-components'


export const StPalpite = styled.section`
    width: 100%;
    background-color: #fff;
    color: #000;
    padding-top:80px;
    border-radius: 10px;

    .card-palpite {
        padding-top: 30px;
    }
    .lado-verde {
        background-color: ${props => props.theme.colors.primary};
        padding: 10px 20px;
        color: #fff;
        border-radius: 0 9px 9px 0;


    }
    .lado-cinza {
        background-color: ${props => props.theme.colors.cinza1};
        /* padding: 10px 20px; */


    }
    .col-time-1, .col-time-2 {
        /* font-size: 3vw !important; */
    }
    .palpite-card-info {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        /* font-size:3vw ; */
        line-height: 12px;
        display: flex;
        align-items: center;

        /* Cor – Texto Corrido */

        color: #282828;
        padding:10px 0;
    }
    .lado-visitante {
        text-align:right ;
        /* font-size: 3vw !important; */


        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
    }
    .lado-mandante {
        /* font-size: 3vw !important; */

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start; 
    }
    .lado-mandante img {
        margin-right: 5px;
    }
    .lado-visitante img {
        margin-left: 5px;
    }
    .box-input-verde {
        text-align:right ;
        display:flex ;
        justify-content: end;
        align-items:    center;

    }
    .box-input-cinza {
        display:flex ;
        justify-content:start ;
        align-items: center;
    }
    .card-palpite input{
        width: 40px;
        height:40px ;
        max-width: 100%;
        border-radius: 4px;
        border:none;
        outline: none;
        text-align: center;
    }

    @media(max-width:340px){
        .card-palpite input {
            height: 20px;
        }
    }
    span.versus {
        position: absolute;
        font-size: 18px;
        z-index: 12;
        left: 50%;
        margin-left: -6px;
    
        font-weight: bold;
        color:#fff;
    }

    @media(max-width:340px){
        /* span.versus {
            top: 9px;
        }  */
    }

    .card-flex-palpite {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* MOBILE */
    @media(max-width:991px){

    .lado-visitante, .palpite-card-info{
            font-size: 3vw !important;
        }
  }

    /* DESKTOP */
    @media(min-width:992px){
        .lado-visitante, .palpite-card-info{
            font-size: 12px !important;
        }
        .lado-verde, .card-flex-palpite {
            border-radius: 10px
        }
        .lado-cinza {
            border-radius: 10px;
        }

    }
`