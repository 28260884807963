import {Container, Row, Col} from 'react-bootstrap'
import { StMenuBottom } from "./style"

// Icons
import HomeIcon from '../../assets/images/home.svg'
import GrapIcon from '../../assets/images/graph.svg'
import BallIcon from '../../assets/images/ball.svg'
import UserIcon from '../../assets/images/user.svg'


function GoRanking(){
    window.location = '/ranking'
}
function GoPalpite(){
    window.location = '/'
}
function GoPontos(){
    window.location = '/pontos';
}

export default function MenuBottom(){
    return(
        <StMenuBottom className="d-lg-none">
        <Container fluid className='main-box text-center p-3'>
            <Row>
                    <Col>
                        <button style={{background:'none', border:'none'}} onClick={GoPalpite}><img src={HomeIcon} alt="Home" /></button>
                    </Col>
                    <Col>
                        <button style={{background:'none', border:'none'}} onClick={GoRanking}><img src={GrapIcon} alt="Estatisticas" /></button>
                    </Col>
                    <Col>
                        <button style={{background:'none', border:'none'}}  onClick={GoPontos}><img src={BallIcon} alt="Ball Icon" /></button>
                    </Col>
                    <Col>
                        <button style={{background:'none', border:'none'}}><img src={UserIcon} alt="User" /></button>
                    </Col>
            </Row>
        </Container>
    </StMenuBottom>
    )
}