import { StMenuDesktop } from "./style"
import {Container, Row, Col, Nav} from 'react-bootstrap'

import headerLogo from '../../assets/images/header.svg'
import logo from '../../assets/images/logo.svg'
import MenuIcon from '../../assets/images/menu.svg'

import noAvatar from '../../assets/images/noavatar.jpg'
import { useState } from "react"

export default function MenuDesktop(){
 
    return(
        <StMenuDesktop className="d-none d-lg-flex">
        <Container fluid className='menu-fluid d-flex align-items-center justify-content-center'>
                <Row>
                      <Col lg={3} className="d-flex align-items-center justify-content-center">
                        <a href="/"><img className="img-fluid responsive" src={logo} /></a>
                      </Col>
                      <Col lg={8} className="d-flex align-items-center justify-content-center">
                      <Nav defaultActiveKey="/home" as="ul" className="links-desktop">
                        <Nav.Item as="li">
                            <Nav.Link href="palpite">Palpite</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link href="ranking">Ranking</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link href="como-funciona">Como funciona</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link href="pontos">Seus pontos</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link href="campeonatos">Campeonatos</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link href="videos">Videos</Nav.Link>
                        </Nav.Item>
                        </Nav>
                      </Col>
                      <Col lg={1} className="d-flex align-items-center justify-content-center">

                            <a href="/perfil">
                                {localStorage.getItem('avatar') ? 
                                (<><img src={localStorage.getItem('avatar')} style={{width:'38px', borderRadius:'50%'}} alt="User" /></>)
                                 : 
                                (<><img src={noAvatar} style={{width:'38px', borderRadius:'50%'}} alt="User" /></>)}
                                
                            </a>
                      </Col>
                </Row>
        </Container>
        </StMenuDesktop>
    )
}