import { StMenu } from "./style"
import {Container, Row, Col} from 'react-bootstrap'

import headerLogo from '../../assets/images/header.svg'
import logo from '../../assets/images/logo.svg'
import MenuIcon from '../../assets/images/menu.svg'

import userAvatar from '../../assets/images/avatar.png'
import { useState } from "react"

import arrow from '../../assets/images/arrow.svg'

import homeIcon from '../../assets/images/home.svg'
import ballIcon from '../../assets/images/ball.svg'
import graphIcon from '../../assets/images/graph.svg'
import askIcon from '../../assets/images/askIcon.svg'
import starIcon from '../../assets/images/star.svg'
import winnerIcon from '../../assets/images/winner.svg'
import videoIcon from '../../assets/images/video.svg'

import closeIcon from '../../assets/images/close.svg'

import 'animate.css';

export default function MenuTop(){
    const [showMenuFull, setShowMenuFull] = useState(false)

    function openMenu(){
        setShowMenuFull(true)
    }
    function closeMenu(){
        setShowMenuFull(false)
    }
    return(
        <StMenu className="d-lg-none">
        <Container fluid className='menu-fluid'>
                <Row>
                        <Col xs={10} className="menu-logo-closed">
                            <a href="/"><img src={logo} alt="Logo Bolao Alviverde" /></a>
                        </Col>
                        <Col xs={2} className="menu-hamburguer">
                            <button onClick={openMenu}><img src={MenuIcon} alt="Menu" /> </button>
                        </Col>
                </Row>
        </Container>
        {showMenuFull && (<>
            <div className='menu-full animate__animated animate__slideInLeft animate__faster'>
                <Container fluid>
                    <Container>
                    <Row>
                        <Col className="d-flex justify-content-end pt-4"><img src={MenuIcon} alt="Menu" onClick={closeMenu} /></Col>
                    </Row>
                    <Row>
                        <Col>
                        <div className="media">
                            <img src={localStorage.getItem('avatar')} style={{width:'38px', borderRadius:'50%'}} className="mr-3" alt="..." />
                            <div className="media-body text-left">
                                <h5 className="mt-0">{localStorage.getItem('name')}</h5>
                                <span><a href="/perfil">Editar perfil</a></span>
                            </div>
                        </div>
                        </Col>
                    </Row>
                    </Container>
                    <Row className="d-flex justify-content-between align-items-center border-bottom pt-4 pb-4 mt-5">
                        <Col xs={10} className="d-flex">
                            <Col xs={1}>
                                <img src={homeIcon} />
                            </Col>
                            <Col xs={8} className="text-left">
                                <a style={{color:'#fff'}} href="/">Home</a>
                            </Col>
                        </Col>
                        <Col xs={2} className="text-center">
                            <img src={arrow} />
                        </Col>
                    </Row>

                    <Row className="d-flex justify-content-between align-items-center border-bottom pt-4 pb-4">
                        <Col xs={10} className="d-flex">
                            <Col xs={1}>
                                <img src={ballIcon} />
                            </Col>
                            <Col xs={8} className="text-left">
                                <a style={{color:'#fff'}} href="/palpite">Dê seu palpite</a>
                            </Col>
                        </Col>
                        <Col xs={2} className="text-center">
                            <img src={arrow} />
                        </Col>
                    </Row>


                    <Row className="d-flex justify-content-between align-items-center border-bottom pt-4 pb-4">
                        <Col xs={10} className="d-flex">
                            <Col xs={1}>
                                <img src={graphIcon} />
                            </Col>
                            <Col xs={8} className="text-left">
                                <a style={{color:'#fff'}} href="/ranking">Ranking</a>
                            </Col>
                        </Col>
                        <Col xs={2} className="text-center">
                            <img src={arrow} />
                        </Col>
                    </Row>

                    

                    <Row className="d-flex justify-content-between align-items-center border-bottom pt-4 pb-4">
                        <Col xs={10} className="d-flex">
                            <Col xs={1}>
                                <img src={askIcon} />
                            </Col>
                            <Col xs={8} className="text-left">
                                <a style={{color:'#fff'}} href="/como-funciona">Como funciona</a>
                            </Col>
                        </Col>
                        <Col xs={2} className="text-center">
                            <img src={arrow} />
                        </Col>
                    </Row>

                    

                    <Row className="d-flex justify-content-between align-items-center border-bottom pt-4 pb-4">
                        <Col xs={10} className="d-flex">
                            <Col xs={1}>
                                <img src={starIcon} height={24} />
                            </Col>
                            <Col xs={8} className="text-left">
                                <a style={{color:'#fff'}} href="/pontos">Seus pontos</a>
                            </Col>
                        </Col>
                        <Col xs={2} className="text-center">
                            <img src={arrow} />
                        </Col>
                    </Row>
                   

                    <Row className="d-flex justify-content-between align-items-center border-bottom pt-4 pb-4">
                        <Col xs={10} className="d-flex">
                            <Col xs={1}>
                                <img src={winnerIcon} />
                            </Col>
                            <Col xs={8} className="text-left">
                                <a style={{color:'#fff'}} href="/campeonatos">Campeonatos</a>
                            </Col>
                        </Col>
                        <Col xs={2} className="text-center">
                            <img src={arrow} />
                        </Col>
                    </Row>


                    <Row className="d-flex justify-content-between align-items-center border-bottom pt-4 pb-4">
                        <Col xs={10} className="d-flex">
                            <Col xs={1}>
                                <img src={videoIcon} />
                            </Col>
                            <Col xs={8} className="text-left">
                                <a style={{color:'#fff'}} href="/videos">Vídeos</a>
                            </Col>
                        </Col>
                        <Col xs={2} className="text-center">
                            <img src={arrow} />
                        </Col>
                    </Row>


                    <Row>
                        <Col className="text-left pt-4" onClick={()=> {}}>
                            <img src={closeIcon}/> <span>Sair</span>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>)}

        </StMenu>
    )
}