import styled from 'styled-components'


export const StRanking = styled.section`
    width: 100%;
    background-color: #fff;
    color: #000;
    padding-top:30px;
    border-radius: 10px;

    .card-ranking-item  {
        margin-top:10px;
        margin-bottom: 10px;
        padding: 14px 0;
    }
    .card-ranking-item-owner {
        margin-top:10px;
        margin-bottom: 10px;
        padding: 14px 0;
        background-color: ${props => props.theme.colors.primary};
        color: #fff;
        margin-bottom: 30px;
    }
    .card-ranking-item:nth-child(odd){
        background-color: ${props => props.theme.colors.cinza2};
    }
    .card-item-ranking-1 {
        background-color: ${props => props.theme.colors.cinza1};

    }
    .card-ranking-item:nth-child(2){
        background-color: ${props => props.theme.colors.secundary};
        color: #fff;
    }
    .pontos-box, .posicao-box {
        background-color: #fff;
        /* padding: 2px 22px; */
        border-radius: 10px;
        width: 65px;
        max-width: 100%;
        

        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: #282828;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .info-card-titles {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 8px;
        line-height: 10px;
        display: flex;
        align-items: center;
        text-transform: uppercase;

        /* Verde Palmeiras – 01 */

        color: ${props => props.theme.colors.primary};
    }
`