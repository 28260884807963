import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'

import OwlCarousel from 'react-owl-carousel2'
import 'react-owl-carousel2/src/owl.theme.default.css'
import 'react-owl-carousel2/lib/styles.css'
import { StTitleAnuncio, StTextAnuncio, StCarouselBlock, FluidAnuncio} from "./style"

export default function CardAnuncio({title, icon, widthBox = 7}){

    const options = {
		items: 1,
		nav: false,
		autoplay: false,
		dots: true,
		loop: true,
		autoHeight: true,
		animateIn: 'fadeIn',
		animateOut: 'fadeOut',
        
    };
        return(
        <FluidAnuncio className='pt-5 pb-5'>

        <Container fluid className='fluid-anuncio'> 
            <Container>
            <Row>
            <Col xs={12} lg={{offset:0, span:5}} className="anuncio-container-main-info">
                <Col className='mt-4 mb-4'>
                    <Row className='mb-4'><img className='mr-1' src={icon} /><StTitleAnuncio className='card-anuncio-title'>{title}</StTitleAnuncio></Row>
                    <Row><StTextAnuncio>Mas lembre-se: precisa ser até 1 hora antes da<br />partida acontecer</StTextAnuncio> </Row>
                </Col>
            </Col>
            <Col xs={12} lg={widthBox} className="anuncio-home-container-slide">

            <OwlCarousel  options={options} >
                <StCarouselBlock>1</StCarouselBlock>
                <StCarouselBlock>2</StCarouselBlock>
                <StCarouselBlock>3</StCarouselBlock>
            </OwlCarousel>
            </Col>
            </Row>
            </Container>
        </Container>
        </FluidAnuncio>
    )
}