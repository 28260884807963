import React from 'react'
import ReactDom from 'react-dom'
import { StModalDeleteUser } from "./style"


const portalRoot = document.getElementById("portal-root") 

export default function UIModal({children, isOpen, close, classNome = "pattern"}){
    
    if(!isOpen){
        return null
    }
    return ReactDom.createPortal(
        <StModalDeleteUser className="ui-modal__overlay" 
        onClick={() => {
            // fecha ao clicar fora do conteúdo do modal
            close();
          }}
          > 
            <div className={`ui-modal styled-modal ${classNome}`}
                    onClick={e => {
                        // do not close modal if anything inside modal content is clicked
                        e.stopPropagation();
                      }}>



                
                {children}
            </div>
        </StModalDeleteUser>,
        portalRoot
    )

}